import React, { useEffect, useRef, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import ProductPonsultationFoot from '@@/components/ProductConsultation';
import './index.scoped.scss';
import DialogDom from '@@/components/dialog';
import { Helmet } from 'react-helmet';

import spImg from '@@/assets/img/about/sp-img.png';
import yjImg from '@@/assets/img/about/yj-img.png';
import smImg from '@@/assets/img/about/sm-img.png';


const AboutUs = () => {
    const [dateArray, setDateArray] = useState<number[]>([2023, 2022, 2021, 2020, 2019, 2018, 2017]);

    const [memorabiliaList, setMemorabiliaList] = useState<{ [key: number]: string[] }>(
        {
            2023: [
                '斩获中国信通院“星河杯”隐私计算大赛全国一等奖；',
                '通过“浙江省高新技术研究开发中心”认定；',
                '隐私计算平台V4.0 "智隐"发布，实现MPC+FL+TEE全栈支撑；',
                '数据要素流通平台V1.0“智通”发布，赋能数据要素安全合规高效流通。',
            ],
            2022: [
                '金智塔隐私计算平台V3.0发布，完成国家金融科技测评中心和中国信通院的功能、性能、安全性全系列评测；',
                '中标浙商银行、宁波银行、浙江农商联合银行、郑州商品交易所隐私计算项目；',
                '金智塔（宁波）数字科技有限公司成立，发力公共数据授权运营；',
                '与华为、中兴、顺丰、中国移动、美的、爱信诺等行业龙头达成合作；',
                '浙江省数据开放大赛舟山市第一名；',
                '获得通商基金追加Pre-A+轮投资；',
                '承担国家重点研发项目(No. 2022YFF0902000)，应用隐私计算技术实现共同富裕指数计算、监测与预警。',
            ],
            2021: [
                '金智塔隐私计算平台V2.0正式发布；',
                '入选杭州「5050计划」企业；',
                '承担浙江省“领雁”研发攻关计划项目；',
                '获得网新科技、通商基金Pre-A轮投资。',
            ],
            2020: [
                '金智塔隐私计算平台V1.0正式发布；',
                '获得浙江省数据开放大赛杭州市一等奖。'
            ],
            2019: [
                '评定为国家高新技术企业；',
                '完成天使+轮融资；',
                '参与国家重点研发项目(No.2018YFB14030003)，正式启动隐私计算技术研发。',
            ],
            2018: [
                '荣获浙江金融科技十大案例 ；',
                '启动“知他”大数据智能平台研发；',
                '荣膺「全球数据资源开发者大赛二等奖」。',
            ],
            2017: [
                '启动金融大数据智能监测平台研发；',
                '完成种子轮与天使轮融资。'
            ]

        },


    );


    const [domKey, setDomKey] = useState<number>(0);
    const [dataTabVal, setDataTabVal] = useState<number>(1);

    const dialogDomRef = useRef<any>(null);

    const dateTabValChange = (val: number) => {
        setDataTabVal(val + 1);
    };

    const openDialog = () => {
        dialogDomRef.current.report();
    };

    const dialogClose = () => {
        setDomKey(new Date().getTime());
    };


    return <>

        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>
        <div className="relative company-profile fxy--center">
            <div className="absolute company-profile-bg" />
            <div className="absolute flex company-profile-center">
                <div className="flex-1">
                    <div className="company-profile-header">金智塔科技</div>
                    <div className="company-profile-title">数据合规流通与应用领军企业</div>
                </div>
            </div>
        </div>

        <div className="company-us fxy--center">
            <div className="flex company-us-center f-fd-c">
                <div className="flex flex-1 company-us-top">
                    <div className="flex-1 company-us-top-box">
                        {/* <div className="company-us-top-box-title">关于金智塔</div> */}
                        <div className='company-us-top-box-txt mt-s36 '>
                            金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与应用领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。
                        </div>
                        <div className='company-us-top-box-txt mt-s24'>
                            作为国产可信数据流通平台的核心攻坚者和支撑者，金智塔科技参与了多个国家、省部级重点研发计划项目，深度参编了由科技部、工信部信通院等组织的国家标准、地方标准、行业标准50余项；拥有数十项发明专利，创新科技成果被李德毅院士和潘云鹤院士鉴定为世界领先水平。
                        </div>
                        <div className='company-us-top-box-txt mt-s24'>
                            在国家重点研发项目支持下，金智塔科技自主研发的数据合规流通与应用产品融合了多方安全计算、联邦学习、区块链等技术，通过了央行国家金融科技中心、工信部中国信通院、公安部等权威机构的10多项评测认证，已广泛应用到数字政府、数字金融、智慧企业等领域，先后联合浙江省统计局、浙江省经信厅、浙商银行、宁波银行、郑州商品交易所、美的集团等数十家行业标杆用户在政务数据多跨共享、公共数据授权运营、金融授信风控和智能营销等场景开展了深入的示范应用。
                        </div>
                    </div>
                    <div className="flex company-us-top-box-video fxy--center">
                        <img className='company-us-top-box-video-content' src={spImg} alt="" onClick={openDialog} />
                    </div>
                </div>

                <div className='flex company-us-bottom'>
                    <div className='us-bottom-box br'>
                        <div className="us-bottom-box-icon">
                            <img src={yjImg} alt="" />
                        </div>
                        <div className="us-bottom-box-header">金智塔愿景</div>
                        <div className="us-bottom-box-txt">成为全球数据智能引领者</div>
                    </div>
                    <div className='us-bottom-box br'>
                        <div className="us-bottom-box-icon">
                            <img src={smImg} alt="" />
                        </div>
                        <div className="us-bottom-box-header">金智塔使命</div>
                        <div className="us-bottom-box-txt">让数智世界更可信</div>
                    </div>
                    <div className='us-bottom-box'>
                        <div className="us-bottom-box-icon">
                            <img src={require('@@/assets/img/about/jzg-img.png')} alt="" />
                        </div>
                        <div className="us-bottom-box-header">金智塔价值观</div>
                        <div className="us-bottom-box-txt">客户至上、诚信为本、天道酬勤、求是创新</div>
                    </div>
                </div>
            </div>
        </div>


        <div className="business-area fxy--center">
            <div className="flex business-area-center f-fd-c">
                <div className="business-area-title ta-c">三大业务领域</div>
                <div className="flex flex-1 business-area-card">
                    <div className='flex-1 business-area-card-box'>
                        <div className="business-area-card-box-img">
                            <img src={require('@@/assets/img/about/szzf-img.png')} alt="" />
                        </div>
                        <div className='business-area-card-box-center'>
                            <div className="business-area-card-box-title">数字政府</div>
                            <div className="business-area-card-box-txt">
                                面向政府部门，提供安全合规的政务数据开放共享
                                整体解决方案，应用于政务数据多跨共享、公共数
                                据授权运营、政务数据对外赋能等场景。
                            </div>
                        </div>

                    </div>
                    <div className='flex-1 business-area-card-box'>
                        <div className="business-area-card-box-img">
                            <img src={require('@@/assets/img/about/szjr-bg.png')} alt="" />
                        </div>
                        <div className='business-area-card-box-center'>
                            <div className="business-area-card-box-title">数字金融</div>
                            <div className="business-area-card-box-txt">
                                面向金融机构，安全融合政务数据、社会数据和金
                                融数据，赋能智能风控、智能授信、个性化营销、
                                反欺诈和智能风险防控等场景应用。
                            </div>
                        </div>

                    </div>
                    <div className='flex-1 business-area-card-box'>
                        <div className="business-area-card-box-img">
                            <img src={require('@@/assets/img/about/zhqy-img.png')} alt="" />
                        </div>
                        <div className='business-area-card-box-center'>
                            <div className="business-area-card-box-title">智慧企业</div>
                            <div className="business-area-card-box-txt">
                                面向传统产业，提供数据要素合规流通解决方案，
                                赋能智能营销、智能选址、智能智造等场景应用。
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        {/* <div className="team fxy--center">
            <div className="flex team-center f-fd-c">
                <div className="team-title ta-c">核心团队</div>

                <div className="flex flex-1 team-card">
                    <div className='team-card-img'>
                        <img src={require('@@/assets/img/about/zp1.png')} alt="" />
                    </div>
                    <div className="flex-1 team-card-box ml-s16">
                        <div className="team-card-box-header">谛听</div>
                        <div className="team-card-box-title">金智塔科技创始人、首席科学家</div>
                        <div className="team-card-box-remark">
                            浙江大学计算机学院教授、博士生导师，浙江大学计算机创新技术研究院副院长，浙江大学人工智能研究所副所长，斯坦福大学访问学者，CCF杰出会员，IEEE高级会员，CCFYOCSEF2015-2016杭州主席，浙江省“万人计划”科技创新领军人才。
                        </div>
                        <div className="team-card-box-txt">
                            主要研究领域：人工智能、隐私计算、智能电商、金融智能等。近几年主持或参加国家和省部级相关项目10多项；著有《智能投顾》（2021年，清华大学出版社）、“十四五”规划教材《金融智能：理论与实践》（2023年，高等教育出版社），参编5本，译著1本；获得国家发明专利6项和软件著作权登记15项；在国际会议和期刊发表论文100多篇。作为主要骨干获得CCF2020“CCF科学技术奖”-科技进步奖卓越奖（一等奖），2018年教育部科技进步二等奖、2014年浙江省科技进步一等奖、2010年度高等学校科学研究优秀成果奖（科学技术）-科技进步奖二等奖等。
                        </div>
                    </div>
                </div>
                <div className="flex flex-1 team-card">
                    <div className="flex-1 team-card-box mr-s16">
                        <div className="team-card-box-header">新文</div>
                        <div className="team-card-box-title">金智塔科技CTO</div>
                        <div className="team-card-box-remark">
                            浙江大学计算机学院特聘研究员、博士生导师，前蚂蚁集团隐私计算高级专家，美国UIUC访问学者，澳州麦考瑞大学 博士生客座导师，浙江大学现代服务创新实验室副主任。
                        </div>
                        <div className="team-card-box-txt">
                            主要研究领域：可信机器学习、隐私计算、图机器学习和推荐系统等。著有《隐私保护机器学习》，是隐私计算 方向的畅销书之一；在TDSC、TKDE、NeurIPS、WWW、SIGIR等国际期刊和会议发表论文80余篇；申请国内外发明专利200余项，其中授权100余项。作为主要骨干获得了2020年度“CCF科学技术奖”-科技进步奖卓越奖以及2021年度中国人工智能学 会“优秀科技成果奖”等。
                        </div>
                    </div>
                    <div className='team-card-img'>
                        <img src={require('@@/assets/img/about/zp2.png')} alt="" />
                    </div>
                </div>

            </div>
        </div> */}

        <div className="relative memorabilia ">
            <img className={`absolute memorabilia-xz ${'xz' + dataTabVal}`} src={require('@@/assets/img/about/gsjjxz-bg.png')} alt="" />
            <div className="absolute flex f-fd-c memorabilia-center">
                <div className="memorabilia-center-header ta-c">金智塔大事记</div>
                <div className='flex flex-1 mt-s46'>
                    <div className="memorabilia-date">
                        {
                            dateArray.map((item, index) =>
                                <div key={index} className={`relative cup memorabilia-date-box ${dataTabVal === (index + 1) ? `box-ativ` : ''}`} onMouseMove={() => dateTabValChange(index)}>
                                    <div className="absolute memorabilia-date-box-txt" >{item}年</div>
                                    {/* <div className="absolute memorabilia-date-box-txt" onMouseMove={() => dateTabValChange(index)} >{item}年</div> */}
                                    {dataTabVal === (index + 1) && <div className='absolute memorabilia-date-box-xian' ></div>}
                                </div>
                            )
                        }

                        <div className='relative memorabilia-date-box '>
                            <div className="absolute memorabilia-date-box-txt"></div>
                        </div>

                    </div>
                    <div className="flex flex-1 memorabilia-card f-fd-c">
                        <div className="flex memorabilia-card-header f-ai-b"><div>{dateArray[dataTabVal - 1]}</div> <span>年</span></div>
                        <div className="flex-1">
                            {
                                memorabiliaList[dateArray[dataTabVal - 1]].map((item: string, index: number) =>
                                    <div className="flex memorabilia-card-box" key={index}>
                                        <div className='flex memorabilia-card-box-header'>
                                            <div className='memorabilia-card-box-heade-icon'><CaretRightOutlined /></div>
                                        </div>
                                        <div className="memorabilia-card-box-txt">{item}</div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="honor fxy--center">
            <div className="flex honor-center ">
                <div className="flex flex-1 f-fd-c">
                    <div className="bonor-header ta-c">荣誉资质</div>
                    <img className="flex-1 bonor-card" src={require('../../../../assets/img/about/bonor.png')} alt="" />
                </div>
            </div>
        </div>

        <div>
            <ProductPonsultationFoot />
        </div>

        <DialogDom key={domKey} ref={dialogDomRef} show={true} dialogClose={() => dialogClose()} />

    </>;
};

export default AboutUs;
