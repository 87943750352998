import React from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import ProductPonsultationFoot from '@@/components/ProductConsultation';
import './index.scoped.scss';
import { Helmet } from 'react-helmet';

const AboutRecruit = () => {


    const openBoss = () => {
        window.open('https://www.zhipin.com/gongsi/job/45b3b331a1508ce40HR72d-5.html');
    };

    return <>

        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>
        <div className="relative recruit fxy--center">
            <div className="absolute recruit-bg" />
            <div className="absolute flex recruit-center">
                <div className="flex-1">
                    <div className="recruit-header">智领未来，由你定义</div>
                    <div className="recruit-title">欢迎加入金智塔科技</div>
                    <div className="recruit-txt">
                        <Button type="primary" onClick={openBoss}>更多职位</Button>
                    </div>
                </div>
            </div>
        </div>


        <div className="recruitment-positions fxy--center">
            <div className="flex recruitment-positions-center">
                <div className="flex-1">
                    <div className="recruitment-positions-header ta-c">热招岗位</div>
                    <div className="flex recruitment-positions-card f-fw-w">


                        <div className="flex f-fd-c recruitment-positions-card-box">
                            <div className="recruitment-positions-card-box-header">
                                <div className="recruitment-positions-card-box-header-title">算法工程师</div>
                                <div className="flex recruitment-positions-card-box-header-tootip">
                                    <div className="recruitment-positions-card-box-header-tootip-txt">经验不限</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">本科</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">杭州</div>
                                </div>
                            </div>
                            <div className='flex flex-1 recruitment-positions-card-box-content f-fd-c'>
                                <div className="box-content flex-1">
                                    <div className='box-content-txt'>
                                        1. 算法工程师熟悉机器学习的基本概念，熟悉机器学习相关的模型和具体算法，例如回归分析模型、关联规则挖掘、分类和聚类算法、协同过滤算法等数据统计模型和挖掘算法，了解完整的数据挖掘过程方法论，有丰富的机器学习算法应用的经验。
                                    </div>
                                    <div className='box-content-txt'>
                                        2. 有从事分布式数据存储与计算平台应用开发经验，有较为系统的海量数据性能处理经验。
                                    </div>
                                    <div className='box-content-txt'>
                                        3.根据项目实际情况，进行深入需求调研、业务场景
                                        设计工作，形成指导性方案；
                                    </div>
                                    <div className='box-content-txt'>
                                        3. 熟悉spark、tensorflow、scikit-learn等常见机器学习框架中的一种。特别是有FL/MPC（联邦学习/多方安全计算）经历者优先。
                                    </div>

                                </div>
                                <div>
                                    <a className='recruitment-positions-card-box-content-link' onClick={openBoss}>立即申请 <ArrowRightOutlined /></a>
                                </div>
                            </div>
                        </div>

                        <div className="flex f-fd-c recruitment-positions-card-box">
                            <div className="recruitment-positions-card-box-header">
                                <div className="recruitment-positions-card-box-header-title">高级解决方案专家</div>
                                <div className="flex recruitment-positions-card-box-header-tootip">
                                    <div className="recruitment-positions-card-box-header-tootip-txt">5-10年</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">本科</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">杭州</div>
                                </div>
                            </div>
                            <div className='flex flex-1 recruitment-positions-card-box-content f-fd-c'>
                                <div className="box-content flex-1">
                                    <div className='box-content-txt'>
                                        1.配合销售完成与用户技术交流，产品和解决方案演
                                        示及技术答疑等工作；
                                    </div>
                                    <div className='box-content-txt'>
                                        2.根据客户需求与现有技术产品，设计详细解决方案，
                                        并引导客户；
                                    </div>
                                    <div className='box-content-txt'>
                                        3.根据项目实际情况，进行深入需求调研、业务场景
                                        设计工作，形成指导性方案；
                                    </div>
                                    <div className='box-content-txt'>
                                        4.收集客户应用场景，并定期向产品和研发团队反馈，
                                        为产品规划和竞争力优化及创新提供决策支持；
                                    </div>
                                    <div className='box-content-txt'>
                                        5.分析市场需求和友商动态，与市场团队一起制定市
                                        场拓展战略；
                                    </div>
                                    <div className='box-content-txt'>
                                        6.组织新人培训和商务培训等工作。
                                    </div>
                                </div>
                                <div>
                                    <a className='recruitment-positions-card-box-content-link' onClick={openBoss}>立即申请 <ArrowRightOutlined /></a>
                                </div>
                            </div>
                        </div>

                        <div className="flex f-fd-c recruitment-positions-card-box">
                            <div className="recruitment-positions-card-box-header">
                                <div className="recruitment-positions-card-box-header-title">测试工程师</div>
                                <div className="flex recruitment-positions-card-box-header-tootip">
                                    <div className="recruitment-positions-card-box-header-tootip-txt">5-10年</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">本科</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">杭州</div>
                                </div>
                            </div>
                            <div className='flex flex-1 recruitment-positions-card-box-content f-fd-c'>
                                <div className="box-content flex-1">
                                    <div className='box-content-txt'>
                                        1、参与项目需求、设计、用例的评审
                                    </div>
                                    <div className='box-content-txt'>
                                        2、负责产品接口、UI自动化测试脚本编写，测试执行；
                                    </div>
                                    <div className='box-content-txt'>
                                        3、研究和引进新的自动化测试工具与测试方法，持续改进自动化测试过程，提高测试效率
                                    </div>
                                    <div className='box-content-txt'>
                                        4、熟悉Appium、Pytest自动化测试框架优先
                                    </div>
                                    <div className='box-content-txt'>
                                        5、了解安全测试Appscan、Nessus
                                    </div>
                                    <div className='box-content-txt'>
                                        6、熟悉docker相关基础命令配置
                                    </div>
                                </div>
                                <div>
                                    <a className='recruitment-positions-card-box-content-link' onClick={openBoss}>立即申请 <ArrowRightOutlined /></a>
                                </div>
                            </div>
                        </div>

                        <div className="flex f-fd-c recruitment-positions-card-box">
                            <div className="recruitment-positions-card-box-header">
                                <div className="recruitment-positions-card-box-header-title">项目经理（金融方向）</div>
                                <div className="flex recruitment-positions-card-box-header-tootip">
                                    <div className="recruitment-positions-card-box-header-tootip-txt">5-10年</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">本科</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">杭州</div>
                                </div>
                            </div>
                            <div className='flex flex-1 recruitment-positions-card-box-content f-fd-c'>
                                <div className="box-content flex-1">
                                    <div className='box-content-txt'>
                                        1、负责商业银行、金融相关大中型软件项目的管理及协调工作，带领团队进行项目建设，确保项目按期按质完成交付
                                    </div>
                                    <div className='box-content-txt'>
                                        2、准确理解用户需求，合理引导用户，合理控制项目边界，可以完成需求分析和方案设计；
                                    </div>
                                    <div className='box-content-txt'>
                                        3、熟悉java B/S结构软件系统的主流开发框架，熟悉银行ETL开发，能辅导和带领团队进行软件系统开发。
                                    </div>
                                    <div className='box-content-txt'>
                                        4、维护用户关系，提高客户满意度；
                                    </div>
                                    <div className='box-content-txt'>
                                        5、领导与管理项目团队，发挥和提高团队成员价值；
                                    </div>
                                    <div className='box-content-txt'>
                                        6、负责项目风险评估，解决项目过程中遇到的质量问题和管理问题；
                                    </div>
                                </div>
                                <div>
                                    <a className='recruitment-positions-card-box-content-link' onClick={openBoss}>立即申请 <ArrowRightOutlined /></a>
                                </div>
                            </div>
                        </div>

                        <div className="flex f-fd-c recruitment-positions-card-box">
                            <div className="recruitment-positions-card-box-header">
                                <div className="recruitment-positions-card-box-header-title">数据挖掘</div>
                                <div className="flex recruitment-positions-card-box-header-tootip">
                                    <div className="recruitment-positions-card-box-header-tootip-txt">3-5年</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">硕士</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">杭州</div>
                                </div>
                            </div>
                            <div className='flex flex-1 recruitment-positions-card-box-content f-fd-c'>
                                <div className="box-content flex-1">
                                    <div className='box-content-txt'>
                                        1. 负责公司机器学习算法研究，支持各类人工智能算法需求
                                    </div>
                                    <div className='box-content-txt'>
                                        2、使用机器学习及大数据技术，为银行、保险和互金机构提供各类AI服务(智能营销、智能风控、用户画像等)；
                                    </div>
                                    <div className='box-content-txt'>
                                        3、基于海量数据建立定位的基础模型，并不断设计开发特征工程,并能不断优化模型，
                                    </div>
                                    <div className='box-content-txt'>
                                        4、追踪学术界和工业界深度学习最近的研究和应用成功；
                                    </div>

                                </div>
                                <div>
                                    <a className='recruitment-positions-card-box-content-link' onClick={openBoss}>立即申请 <ArrowRightOutlined /></a>
                                </div>
                            </div>
                        </div>

                        <div className="flex f-fd-c recruitment-positions-card-box">
                            <div className="recruitment-positions-card-box-header">
                                <div className="recruitment-positions-card-box-header-title">隐私计算研发工程师</div>
                                <div className="flex recruitment-positions-card-box-header-tootip">
                                    <div className="recruitment-positions-card-box-header-tootip-txt">3-5年</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">硕士</div>
                                    <div className="recruitment-positions-card-box-header-tootip-txt">杭州</div>
                                </div>
                            </div>
                            <div className='flex flex-1 recruitment-positions-card-box-content f-fd-c'>
                                <div className="box-content flex-1">
                                    <div className='box-content-txt'>
                                        1、负责分布式计算框架设计及搭建
                                    </div>
                                    <div className='box-content-txt'>
                                        2、负责基于MPC的分布式框架定制开发
                                    </div>
                                    <div className='box-content-txt'>
                                        3、能够针对实际业务提出优化策略及改进方案
                                    </div>
                                    <div className='box-content-txt'>
                                        4、参与安全计算、隐私保护机器学习算法与系统、平台实现
                                    </div>
                                    <div className='box-content-txt'>
                                        5、进行相关前沿技术调研、分析与应用转化
                                    </div>

                                </div>
                                <div>
                                    <a className='recruitment-positions-card-box-content-link' onClick={openBoss}>立即申请 <ArrowRightOutlined /></a>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="recruitment-positions-btn fxy--center">
                        <div className="gdzw fxy--center" onClick={openBoss}>查看更多职位</div>
                    </div>
                </div>
            </div>
        </div>


        {/* <div className="employee-collection fxy--center">
            <div className="flex employee-collection-center">
                <div className="flex-1" >
                    <div className="employee-collection-header ta-c">员工风采</div>
                    <img className='employee-collection-img' src={require('@@/assets/img/about/ygfc-img.png')} alt="" />
                </div>
            </div>
        </div> */}

        <div className="resume-submission fxy--center">
            <div className="flex resume-submission-center">
                <div className="flex flex-1 resume-submission-card f-fd-c">
                    <div className="resume-submission-header ta-c">简历投递方式</div>
                    <div className="flex resume-submission-box">
                        {/* <img className='resume-submission-box-img' src={require('@@/assets/img/about/tdjl-bg.png')} alt="" /> */}
                        <div className="flex flex-1 resume-submission-box-content f-fd-c f-jc-c">
                            <div className="box-content-header">简历发至招聘邮箱：hr@jztdata.com</div>
                            <div className="box-content-title">HR团队将在收到简历的5个工作日内进行处理。 若简历通过，您将立即收到通知。</div>
                            {/* <div className="box-content-remark">添加微信投递简历</div>
                            <img className='box-content-img' src={require('@@/assets/img/about/erweima.png')} alt="" />
                            <div className='box-content-wxremark'>扫码添加HR微信投递简历</div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ProductPonsultationFoot />
    </>;
};

export default AboutRecruit;
