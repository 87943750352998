/* eslint-disable linebreak-style */
import React from 'react';
import { withRouter, useHistory } from 'react-router';
import { CaretLeftOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { scrollToTop } from '@@/utils/common.until';
import './index.scoped.scss';
import zzhtb from '@@/assets/img/home/zzhtb.png';
import wxgzh from '@@/assets/img/about/wxgzh.png';

const FooterBar = () => {
    const history = useHistory();

    const goLink = async (str: string, type?: string) => {
        console.log('-----------1111', type);
        history.push({ pathname: str, search: type });
        await scrollToTop();
    };

    const openLink = (url: string) => {
        window.open(url);
    };
    return (
        <div className="footer-bar">
            <div className="layout flexbt">
                <div className="left flexbt">
                    <ul>
                        <li className="tr">
                            <a >产品介绍</a>
                        </li>
                        <li className="td cup">
                            <a onClick={() => goLink('/mainProd', '1')} >智隐 隐私计算平台</a>
                        </li>
                        <li className="td cup">
                            <a onClick={() => goLink('/mainProd', '2')}>智通 数据要素流通平台</a>
                        </li>
                        <li className="td cup">
                            <a onClick={() => goLink('/mainProd', '3')} >智学 机器学习平台</a>
                        </li>
                        <li className="td cup">
                            <a onClick={() => goLink('/mainProd', '4')} >智算 安全计算平台</a>
                        </li>
                    </ul>
                    <ul>
                        <li className="tr">
                            <a>典型案例</a>
                        </li>
                        <li className="td cup">
                            <a onClick={() => goLink('/case', '1')}>政务数据多跨安全共享与创新应用</a>
                        </li>
                        <li className="td cup">
                            <a onClick={() => goLink('/case', '2')}>某副省级城市数据流通平台</a>
                        </li>
                        {/* <li className="td cup">
                            <a onClick={() => goLink('/case', '3')}>合规融合内外部数据助力共同富裕建设</a>
                        </li> */}
                        <li className="td cup">
                            <a onClick={() => goLink('/case', '4')}>安全合规融合政务数据赋能银行智能风控、授信</a>
                        </li>
                    </ul>
                    <ul>
                        <li className="tr">
                            <a>关于我们</a>
                        </li>
                        <li className="td cup">
                            <a onClick={() => goLink('/about', '')}>公司介绍</a>
                        </li>
                        {/* <li className="td">
                            <a onClick={()=>goLink('/about', '')}>荣誉资质</a>
                        </li> */}
                        <li className="td cup">
                            <a onClick={() => goLink('/recruit', '')}>加入我们</a>
                        </li>
                        <li className="td cup">
                            <a onClick={() => goLink('/contact', '')}>联系我们</a>
                        </li>
                    </ul>
                    {/* <ul>
                        <li className="tr">
                            <a>关于我们</a>
                        </li>
                        <li className="td">
                            <a>平台介绍</a>
                        </li>
                        <li className="td">
                            <a>平台优势</a>
                        </li>
                        <li className="td">
                            <a>联系合作</a>
                        </li>
                        <li className="td">
                            <a>帮助支持</a>
                        </li>
                    </ul> */}
                </div>
                <div className="relative right ">
                    <ul>
                        <li className="tr">
                            <a>联系方式</a>
                        </li>
                        {/* <li className="td atv">
                            <a>咨询方式/商务合作</a>
                        </li> */}
                        <li className="td atv">
                            <a>销售咨询：0571-87209876</a>
                        </li>
                        <li className="td atv">
                            <a>人事咨询：0571-87209876</a>
                        </li>
                        <li className="td atv">
                            <a>商务合作：qis@jztdata.com</a>
                        </li>
                    </ul>

                    <div className="gzwm">关注我们</div>
                    <img className='gzhtb' src={zzhtb} alt="" />
                    <div className='absolute gszzh'>
                        <CaretLeftOutlined className='absolute gszzh-icon' />
                        <img className='gszzh-img' src={wxgzh} alt="" />
                    </div>
                </div>
            </div>
            <div className='layout footer-btm'>
                <div className="footer-btm-yqlj">友情链接：
                    <span onClick={() => openLink('https://www.zju.edu.cn/')}>浙江大学</span><Divider type="vertical" />
                    <span onClick={() => openLink('https://www.insigma.com.cn/')}>浙大网新</span><Divider type="vertical" />
                    <span onClick={() => openLink('http://www.nbtsg.cn/index.html')}>宁波通商</span><Divider type="vertical" />
                </div>
                <div className="footer-btm-txt">
                    版权所有：杭州金智塔科技有限公司  copyright@2016-2023 数据要素合规流通与应用服务商<br />
                    浙ICP备16046109号-1 浙公网安备 33010602008565号
                </div>
            </div>
        </div>
    );
};


export default withRouter(FooterBar);
