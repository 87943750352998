import React, { useEffect, useState } from 'react';
import { Popover, FloatButton } from 'antd';
import './index.scoped.scss';

import phone from '@@/assets/img/drop-down/bar-1.png';
import guanwei from '@@/assets/img/drop-down/bar-2.png';
import zixun from '@@/assets/img/drop-down/bar-3.png';

import gwx from '@@/assets/img/drop-down/erweima.png';
import upScroll from '@@/assets/img/drop-down/bar-4.png';

import jqrImg from '@@/assets/img/qqr.png';


const FloatButtonDom = () => {

    const [obvious, setObvious] = useState<boolean>(true);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setObvious(document.documentElement.scrollTop > 1400);

        });
    }, []);

    const scrollToTop = () => {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, currentScroll - currentScroll / 8);
        }
    };


    const content1 = () => {
        return <div className='float-txt'>
            <p>销售咨询：0571-8720 9876</p>
            <p>人事咨询：0571-8716 2523</p>
        </div>;
    };
    const content2 = () => {
        return <div className='fxy--center f-fd-c'>
            <div className='content2-title ta-c'>关注我们，了解更多</div>
            <img className='content2-img' src={gwx} alt="" />
        </div>;
    };
    const content3 = () => {
        return <div className='float-txt'>
            <p>Content</p>
            <p>Content</p>
        </div>;
    };

    const goLink = () => {
        window.location.href = 'applyTrial';
    };

    return <>
        <div className='FloatButtonDom'>
            <img src={jqrImg} className='FloatButtonDom-jqr' alt="" />
            <div className='float-box' style={{ marginTop: '0px' }}>
                <Popover placement="left" content={content1} title="">
                    <img className='float-img cup' src={phone} alt="" />
                </Popover>
            </div>
            <div className='float-box'>
                <Popover placement="left" content={content2} title="">
                    <img className='float-img cup' src={guanwei} alt="" />
                </Popover>
            </div>
            <div className='float-box'>
                {/* <Popover placement="left" content={content3} title=""> */}
                <img className='float-img cup' src={zixun} alt="" onClick={goLink} />
                {/* </Popover> */}
            </div>
            <div className={`head fxy--center ${obvious ? 'head1' : 'head0'}`}>
                <img className='upScroll cup' src={upScroll} alt="" onClick={scrollToTop} />
            </div>
        </div>
    </>;
};

export default FloatButtonDom;
