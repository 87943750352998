import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { UngroupOutlined, FundProjectionScreenOutlined, FileSearchOutlined, ClusterOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Anchor, Affix } from 'antd';
import './index.scoped.scss';
// import Common from '@common/utils/common.until';
import ProductPonsultationFoot from '@@/components/ProductConsultation';
import { Helmet } from 'react-helmet';


const ClassicCasesDom = () => {
    const history = useHistory();

    const openLink = () => {
        window.location.href = 'applyTrial';
    };


    // 返回到顶部
    const scrollToTop = () => {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, currentScroll - currentScroll / 8);
        }
    };


    useEffect(() => {
        scrollToTop();
    }, []);

    return <>
        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>


        <div className='case-header'>
            <div className={`case-header-bg fxy--center`}></div>
            <div className='case-header-cen fxy--center'>
                <div className='case-header-box'>
                    <div className="case-header-title"> 赋能千行百业数智化转型</div>
                    <div className="case-header-txt">
                        为政府部门、金融机构和企业等搭建数据要素合规流通的桥梁，助力业务更安全、<br />
                        更高效的数智化转型。
                    </div>
                    <div className="case-header-btn cup fxy--center" onClick={openLink}>立即咨询</div>
                </div>
            </div>
        </div>

        <div className="case-box fxy--center">
            <div className="flex case-box-center">
                <div className="flex-1 bg">
                    <div className="case-box-header fxy--center">政务数据多跨安全共享与创新应用</div>
                    <div className="case-box-header-txt fxy--center">打破政府部门间的“数据孤岛”，构建省市县三级一体化横向、纵向的数据要素流通体系，助力建设协同高效的数字政府。</div>
                    <div className="flex case-card">
                        <div className="flex case-card-box f-fd-c transition-hover-up">
                            <div className="case-card-box-header headerImg1">业务挑战1</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">数据协同难</div>
                                <div className="case-card-box-main-txt">“应归尽归”无法真正完全做到，敏感类、明细类数据无法实现全域全量共享共用。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-up">
                            <div className="case-card-box-header headerImg1">业务挑战2</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">安全计算难</div>
                                <div className="case-card-box-main-txt">很多系统具备数据脱敏、数据加密等手段，但数据仍需传输，缺少不出域加密计算的手段。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-up">
                            <div className="case-card-box-header headerImg1">业务挑战3</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">跨域流通难</div>
                                <div className="case-card-box-main-txt">公共数据授权运营跨域数据开发利用、融合计算、市场化流通尚需有力技术支撑。</div>
                            </div>
                        </div>

                    </div>


                    <div className="flex case-card-content case-content-img1">
                        <div className="flex-1">
                            <div className="case-card-content-header">解决方案</div>
                            <div className="case-card-content-border"></div>
                            <div className="case-card-content-txt">


                                金智塔科技基于隐私计算等技术开发了数据融合计算平台，打破了<br />
                                政府部门间的“数据孤岛”并安全接入社会商业数据，构建了省市县<br />
                                级一体化横向、纵向的数据要素流通体系，在保护数据隐私安全的<br />
                                前提下，激发政务数据的应用价值。


                                {/*
                                金智塔科技联合多家银行，运用隐私计算技术对接了浙江省金融<br />
                                综合服务平台，安全融合了市场监督管理局、法院、税务、环保<br />
                                等55个省级政府部门政务数据，合规引入企业纳税总额、企业法<br />
                                人信息、企业年报信息等300多维企业特征数据，助力银行贷前、<br />
                                贷后智能风控等业务的创新发展。 */}
                            </div>
                        </div>
                    </div>

                    <div className="flex case-card">
                        <div className="flex case-card-box f-fd-c transition-hover-down">
                            <div className="case-card-box-header headerImg2">方案价值1</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">安全融合外部数据</div>
                                <div className="case-card-box-main-txt">安全合规融合征信公司商业数据，实现对企业上报数据智能判断，准确率从85%提高到96%，工作效率提升10倍以上。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-down">
                            <div className="case-card-box-header headerImg2">方案价值2</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">实现统计数据共享</div>
                                <div className="case-card-box-main-txt">突破性的实现统计基层数据多跨安全共享5600万次，赋能某省“最多报一次”重大应用。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-down">
                            <div className="case-card-box-header headerImg2">方案价值3</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">助力数字政府建设</div>
                                <div className="case-card-box-main-txt">已在“双碳数据隐私计算”、“亩均论英雄联合隐私统计”等多个业务场景展开创新应用，助力协同高效的数字政府建设。</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <div className="case-box fxy--center">
            <div className="flex case-box-center">
                <div className="flex-1 bg">
                    <div className="case-box-header fxy--center">某副省级城市数据流通平台</div>
                    <div className="case-box-header-txt fxy--center">建立安全、合规、可信、高效的数据加工和交付审查环境，赋能数据要素有序开发利用。</div>
                    <div className="flex case-card">
                        <div className="flex case-card-box f-fd-c transition-hover-up">
                            <div className="case-card-box-header headerImg1">业务挑战1</div>
                            <div className="flex-1 case-card-box-main mainBg1">
                                <div className="case-card-box-main-title">高价值公共数据获取难</div>
                                <div className="case-card-box-main-txt">对外开放的公共数据价值较低，难以形成产业生态；高价值数据获取困难，无法满足多样化业务需求。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-up">
                            <div className="case-card-box-header headerImg1">业务挑战2</div>
                            <div className="flex-1 case-card-box-main mainBg1">
                                <div className="case-card-box-main-title">缺乏公共数据隐私加工环境</div>
                                <div className="case-card-box-main-txt">尚未建立公共数据隐私计算加工环境，不利于公共数据的统筹运营和价值释放。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-up">
                            <div className="case-card-box-header headerImg1">业务挑战3</div>
                            <div className="flex-1 case-card-box-main mainBg1">
                                <div className="case-card-box-main-title">未建立全面的数据产品安全审查机制</div>
                                <div className="case-card-box-main-txt">公共数据产品有多种形态，如数据包、数据模型、数据接口、数据服务和数据报告等，每种形态都需要针对性地定制安全审查机制。</div>
                            </div>
                        </div>

                    </div>


                    <div className="flex case-card-content case-content-img2">
                        <div className="flex-1">
                            <div className="case-card-content-header">解决方案</div>
                            <div className="case-card-content-border"></div>
                            <div className="case-card-content-txt">
                                金智塔科技应用隐私计算、可信环境计算、区块链存证等技术<br />
                                手段，为某副省级城市建设数据流通平台，构建了一个安全、<br />
                                合规、可信、高效的数据加工和交付审查环境，赋能数据要素<br />
                                有序开放利用，推进数据要素市场化流通。
                            </div>
                        </div>
                    </div>

                    <div className="flex case-card">
                        <div className="flex case-card-box f-fd-c transition-hover-down">
                            <div className="case-card-box-header headerImg2">方案价值1</div>
                            <div className="flex-1 case-card-box-main mainBg2">
                                <div className="case-card-box-main-title">响应国家政策要求，释放数据价值</div>
                                <div className="case-card-box-main-txt">培育、发展数据要素市场，盘活数据资源；并提高数据利用效率，释放数据要素价值。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-down">
                            <div className="case-card-box-header headerImg2">方案价值2</div>
                            <div className="flex-1 case-card-box-main mainBg2">
                                <div className="case-card-box-main-title">规范数据开发利用，支撑数据授权运营</div>
                                <div className="case-card-box-main-txt">应用隐私计算、区块链等技术，规范数据开发利用行为，服务数据主管部门、政府监管部门、授权运营单位，支撑数据授权运营。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-down">
                            <div className="case-card-box-header headerImg2">方案价值3</div>
                            <div className="flex-1 case-card-box-main mainBg2">
                                <div className="case-card-box-main-title">推动数据融合创新，培育数字产业生态</div>
                                <div className="case-card-box-main-txt">降低社会机构获取数据资源的成本，推动公共数据和社会数据的深层次融合，健全数据开发利用体系，培育地区数字产业化、产业数字化生态。</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <div className="case-box fxy--center">
            <div className="flex case-box-center">
                <div className="flex-1 bg">
                    <div className="case-box-header fxy--center">安全合规融合政务数据赋能银行智能风控、授信</div>
                    <div className="case-box-header-txt fxy--center">安全合规融合几十个政府部门数据，助力银行贷前、贷后智能风控等业务的创新发展。</div>
                    <div className="flex case-card">
                        <div className="flex case-card-box f-fd-c transition-hover-up">
                            <div className="case-card-box-header headerImg1">业务挑战1</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">数据孤岛</div>
                                <div className="case-card-box-main-txt">数字化时代，为了更精准实现业务价值，金融机构往往需要多维度数据，需要融合大量的外部数据。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-up">
                            <div className="case-card-box-header headerImg1">业务挑战2</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">数据安全</div>
                                <div className="case-card-box-main-txt">随着国家系列涉及数据安全、个人隐私的法律、法规的颁布，数据直接使用将违反数据安全和隐私保护的相关规定。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-up">
                            <div className="case-card-box-header headerImg1">业务挑战3</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">运营成本高</div>
                                <div className="case-card-box-main-txt">小微科创企业自身缺少可担保的抵押物，又缺乏足够的数据支持，大大增加了金融机构的风险和成本。</div>
                            </div>
                        </div>

                    </div>


                    <div className="flex case-card-content case-content-img3">
                        <div className="flex-1">
                            <div className="case-card-content-header">解决方案</div>
                            <div className="case-card-content-border"></div>
                            <div className="case-card-content-txt">


                                金智塔科技联合多家银行，运用隐私计算技术对接了浙江省金融<br />
                                综合服务平台，安全融合了市场监督管理局、法院、税务、环保<br />
                                等55个省级政府部门政务数据，合规引入企业纳税总额、企业<br />
                                法人信息、企业年报信息等300多维企业特征数据，助力银行<br />
                                贷前、贷后智能风控等业务的创新发展。


                                {/* 金智塔科技联合多家银行，运用隐私计算技术对接了浙江省<br />
                                金融综合服务平台，安全融合了市场监督管理局、法院、<br />
                                税务、环保等55个省级政府部门政务数据，合规引入企业<br />
                                纳税总额、企业法人信息、企业年报信息等300多维企业<br />
                                特征数据，助力银行贷前、贷后智能风控等业务的创新发展。 */}
                            </div>
                        </div>
                    </div>

                    <div className="flex case-card">
                        <div className="flex case-card-box f-fd-c transition-hover-down">
                            <div className="case-card-box-header headerImg2">方案价值1</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">创新政务数据应用</div>
                                <div className="case-card-box-main-txt">实现了省金综平台首次对外赋能金融机构，为政务数据安全合规创新应用积累了丰富经验。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-down">
                            <div className="case-card-box-header headerImg2">方案价值2</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">创新金融数字化</div>
                                <div className="case-card-box-main-txt">通过运用隐私计算技术，安全合规融合了更多受隐私、安全因素限制的政务数据，为金融机构数字化创新发展提供了新动能。</div>
                            </div>
                        </div>
                        <div className="flex case-card-box f-fd-c transition-hover-down">
                            <div className="case-card-box-header headerImg2">方案价值3</div>
                            <div className="flex-1 case-card-box-main">
                                <div className="case-card-box-main-title">创新普惠金融服务</div>
                                <div className="case-card-box-main-txt">实现了中小微企业融资量增、面扩、价降、提质，同时降低了银行经营风险，为普惠金融高质量可持续发展提供有力支撑。</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <ProductPonsultationFoot />
    </>;
};


export default ClassicCasesDom;
