import React, { useEffect } from 'react';
import './index.scoped.scss';
import { useHistory } from 'react-router-dom';

const ProductPonsultationFoot = () => {
    const history = useHistory();
    const openLink = () => {
        window.location.href = 'applyTrial';
    };

    return <div className="empowering-enterprises fxy--center f-fd-c">
        <div className='empowering-enterprises-title fxy--center'>赋能数据要素合规流通与应用，助力千行百业数字化转型</div>
        <div className="empowering-enterprises-btn fxy--center cup" onClick={openLink}>申请试用</div>
    </div>;
};


export default ProductPonsultationFoot;
