(function(t) {
  const e=function() {
    try {
      return !!Symbol.iterator
    } catch (e) {
      return false
    }
  }; const r=e(); const n=function(t) {
    const e={next: function() {
      const e=t.shift(); return {done: e===void 0, value: e}
    }}; if (r) {
      e[Symbol.iterator]=function() {
        return e
      }
    } return e
  }; const i=function(e) {
    return encodeURIComponent(e).replace(/%20/g, '+')
  }; const o=function(e) {
    return decodeURIComponent(String(e).replace(/\+/g, ' '))
  }; const a=function() {
    var a=function(e) {
      Object.defineProperty(this, '_entries', {writable: true, value: {}}); const t=typeof e; if (t==='undefined') {} else if (t==='string') {
        if (e!=='') {
          this._fromString(e)
        }
      } else if (e instanceof a) {
        const r=this; e.forEach(function(e, t) {
          r.append(t, e)
        })
      } else if (e!==null&&t==='object') {
        if (Object.prototype.toString.call(e)==='[object Array]') {
          for (let n=0; n<e.length; n++) {
            const i=e[n]; if (Object.prototype.toString.call(i)==='[object Array]'||i.length!==2) {
              this.append(i[0], i[1])
            } else {
              throw new TypeError('Expected [string, any] as entry at index '+n+' of URLSearchParams\'s input')
            }
          }
        } else {
          for (const o in e) {
            if (e.hasOwnProperty(o)) {
              this.append(o, e[o])
            }
          }
        }
      } else {
        throw new TypeError('Unsupported input\'s type for URLSearchParams')
      }
    }; const e=a.prototype; e.append=function(e, t) {
      if (e in this._entries) {
        this._entries[e].push(String(t))
      } else {
        this._entries[e]=[String(t)]
      }
    }; e.delete=function(e) {
      delete this._entries[e]
    }; e.get=function(e) {
      return e in this._entries?this._entries[e][0]:null
    }; e.getAll=function(e) {
      return e in this._entries?this._entries[e].slice(0):[]
    }; e.has=function(e) {
      return e in this._entries
    }; e.set=function(e, t) {
      this._entries[e]=[String(t)]
    }; e.forEach=function(e, t) {
      let r; for (const n in this._entries) {
        if (this._entries.hasOwnProperty(n)) {
          r=this._entries[n]; for (let i=0; i<r.length; i++) {
            e.call(t, r[i], n, this)
          }
        }
      }
    }; e.keys=function() {
      const r=[]; this.forEach(function(e, t) {
        r.push(t)
      }); return n(r)
    }; e.values=function() {
      const t=[]; this.forEach(function(e) {
        t.push(e)
      }); return n(t)
    }; e.entries=function() {
      const r=[]; this.forEach(function(e, t) {
        r.push([t, e])
      }); return n(r)
    }; if (r) {
      e[Symbol.iterator]=e.entries
    }e.toString=function() {
      const r=[]; this.forEach(function(e, t) {
        r.push(i(t)+'='+i(e))
      }); return r.join('&')
    }; t.URLSearchParams=a
  }; const s=function() {
    try {
      const e=t.URLSearchParams; return new e('?a=1').toString()==='a=1'&&typeof e.prototype.set==='function'&&typeof e.prototype.entries==='function'
    } catch (e) {
      return false
    }
  }; if (!s()) {
    a()
  } const f=t.URLSearchParams.prototype; if (typeof f.sort!=='function') {
    f.sort=function() {
      const r=this; const n=[]; this.forEach(function(e, t) {
        n.push([t, e]); if (!r._entries) {
          r.delete(t)
        }
      }); n.sort(function(e, t) {
        if (e[0]<t[0]) {
          return -1
        } else if (e[0]>t[0]) {
          return +1
        } else {
          return 0
        }
      }); if (r._entries) {
        r._entries={}
      } for (let e=0; e<n.length; e++) {
        this.append(n[e][0], n[e][1])
      }
    }
  } if (typeof f._fromString!=='function') {
    Object.defineProperty(f, '_fromString', {enumerable: false, configurable: false, writable: false, value: function(e) {
      if (this._entries) {
        this._entries={}
      } else {
        const r=[]; this.forEach(function(e, t) {
          r.push(t)
        }); for (var t=0; t<r.length; t++) {
          this.delete(r[t])
        }
      }e=e.replace(/^\?/, ''); const n=e.split('&'); let i; for (var t=0; t<n.length; t++) {
        i=n[t].split('='); this.append(o(i[0]), i.length>1?o(i[1]):'')
      }
    }})
  }
})(typeof global!=='undefined'?global:typeof window!=='undefined'?window:typeof self!=='undefined'?self:this); (function(u) {
  const e=function() {
    try {
      const e=new u.URL('b', 'http://a'); e.pathname='c d'; return e.href==='http://a/c%20d'&&e.searchParams
    } catch (e) {
      return false
    }
  }; const t=function() {
    const t=u.URL; const e=function(e, t) {
      if (typeof e!=='string')e=String(e); if (t&&typeof t!=='string')t=String(t); let r=document; let n; if (t&&(u.location===void 0||t!==u.location.href)) {
        t=t.toLowerCase(); r=document.implementation.createHTMLDocument(''); n=r.createElement('base'); n.href=t; r.head.appendChild(n); try {
          if (n.href.indexOf(t)!==0) throw new Error(n.href)
        } catch (e) {
          throw new Error('URL unable to set base '+t+' due to '+e)
        }
      } const i=r.createElement('a'); i.href=e; if (n) {
        r.body.appendChild(i); i.href=i.href
      } const o=r.createElement('input'); o.type='url'; o.value=e; if (i.protocol===':'||!/:/.test(i.href)||!o.checkValidity()&&!t) {
        throw new TypeError('Invalid URL')
      }Object.defineProperty(this, '_anchorElement', {value: i}); const a=new u.URLSearchParams(this.search); let s=true; let f=true; const c=this; ['append', 'delete', 'set'].forEach(function(e) {
        const t=a[e]; a[e]=function() {
          t.apply(a, arguments); if (s) {
            f=false; c.search=a.toString(); f=true
          }
        }
      }); Object.defineProperty(this, 'searchParams', {value: a, enumerable: true}); let h=void 0; Object.defineProperty(this, '_updateSearchParams', {enumerable: false, configurable: false, writable: false, value: function() {
        if (this.search!==h) {
          h=this.search; if (f) {
            s=false; this.searchParams._fromString(this.search); s=true
          }
        }
      }})
    }; const r=e.prototype; const n=function(t) {
      Object.defineProperty(r, t, {get: function() {
        return this._anchorElement[t]
      }, set: function(e) {
        this._anchorElement[t]=e
      }, enumerable: true})
    }; ['hash', 'host', 'hostname', 'port', 'protocol'].forEach(function(e) {
      n(e)
    }); Object.defineProperty(r, 'search', {get: function() {
      return this._anchorElement['search']
    }, set: function(e) {
      this._anchorElement['search']=e; this._updateSearchParams()
    }, enumerable: true}); Object.defineProperties(r, {toString: {get: function() {
      const e=this; return function() {
        return e.href
      }
    }}, href: {get: function() {
      return this._anchorElement.href.replace(/\?$/, '')
    }, set: function(e) {
      this._anchorElement.href=e; this._updateSearchParams()
    }, enumerable: true}, pathname: {get: function() {
      return this._anchorElement.pathname.replace(/(^\/?)/, '/')
    }, set: function(e) {
      this._anchorElement.pathname=e
    }, enumerable: true}, origin: {get: function() {
      const e={'http:': 80, 'https:': 443, 'ftp:': 21}[this._anchorElement.protocol]; const t=this._anchorElement.port!=e&&this._anchorElement.port!==''; return this._anchorElement.protocol+'//'+this._anchorElement.hostname+(t?':'+this._anchorElement.port:'')
    }, enumerable: true}, password: {get: function() {
      return ''
    }, set: function(e) {}, enumerable: true}, username: {get: function() {
      return ''
    }, set: function(e) {}, enumerable: true}}); e.createObjectURL=function(e) {
      return t.createObjectURL.apply(t, arguments)
    }; e.revokeObjectURL=function(e) {
      return t.revokeObjectURL.apply(t, arguments)
    }; u.URL=e
  }; if (!e()) {
    t()
  } if (u.location!==void 0&&!('origin'in u.location)) {
    const r=function() {
      return u.location.protocol+'//'+u.location.hostname+(u.location.port?':'+u.location.port:'')
    }; try {
      Object.defineProperty(u.location, 'origin', {get: r, enumerable: true})
    } catch (e) {
      setInterval(function() {
        u.location.origin=r()
      }, 100)
    }
  }
})(typeof global!=='undefined'?global:typeof window!=='undefined'?window:typeof self!=='undefined'?self:this);
