import React, { useEffect, useRef, useState } from 'react';
import { Select, Row, Form, Input, Col, message } from 'antd';
const { TextArea } = Input;
import { ArrowRightOutlined } from '@ant-design/icons';
import './index.scoped.scss';
import { HttpService } from '@@/services/http.service';
import { Helmet } from 'react-helmet';


const AboutContact = () => {

    const [mapIndex, setMpaIndex] = useState<number>(1);

    const formRef = useRef<any>(null);

    const [map, setMap] = useState<any>(null);

    useEffect(() => {
        // 高德地图
        // 在组件挂载后执行
        loadExternalScript('https://webapi.amap.com/maps?v=2.0&key=0dac96eb0d35ce5f6316d2d2cbca729a');

        setTimeout(async () => {
            await init();
        }, 1000);
        return () => {
            // 在组件卸载时移除动态加载的脚本
            removeExternalScript('https://webapi.amap.com/maps?v=2.0&key=0dac96eb0d35ce5f6316d2d2cbca729a');
        };
    }, []);

    const loadExternalScript = (url: any) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
    };

    const removeExternalScript = (url: any) => {
        const script = document.querySelector(`script[src="${url}"]`);
        if (script) {
            script.remove();
        }
    };

    const init = () => {
        const map = new AMap.Map('mapDom', {
            viewMode: '2D', // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D'
            zoom: 13, // 初始化地图层级
            center: [120.117203, 30.289919] // 初始化地图中心点
        });
        setMap(map);


        const marker = new AMap.Marker({
            position: [120.117203, 30.289919] // 位置
        });
        marker.setMap(map);
        marker.setLabel({
            direction: 'top',
            offset: new AMap.Pixel(10, 0), // 设置文本标注偏移量
            content: '<div class=\'info\'>杭州金智塔科技有限公司</div>', // 设置文本标注内容
        });


        const marker1 = new AMap.Marker({
            position: [121.628233, 29.88941] // 位置
        });
        marker1.setMap(map);
        marker1.setLabel({
            direction: 'top',
            offset: new AMap.Pixel(10, 0), // 设置文本标注偏移量
            content: '<div class=\'info\'>金智塔(宁波)数字科技有限公司</div>', // 设置文本标注内容
        });
    };

    const mapClick = (val: number) => {
        setMpaIndex(val);
        if (val === 1) {
            map.setCenter([120.117203, 30.289919]);
        } else {
            map.setCenter([121.628233, 29.88941]);
        }

    };


    const layout = {
        labelCol: { span: 0.5 },
        wrapperCol: { span: 23.5 },
    };


    const saveFromAjax = async (row: any) => {
        const [err, res] = await HttpService('POST', 'consultation/insert', row);
        console.log('res.dataPackage:::', res);

        message.success('提交成功');
        formRef.current.resetFields();
    };

    const saveFrom = () => {
        formRef.current.validateFields().then((res: any) => {
            console.log(res);
            saveFromAjax(res);
        }).catch((err: any) => {
            console.log(err);

        });
    };

    return <>

        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>

        <div className="relative contact fxy--center">
            <div className="absolute contact-bg" />
            <div className="absolute flex contact-center">
                <div className="flex-1">
                    <div className="contact-header">联系我们</div>
                    <div className="contact-title">客户至上、诚信为本、天道酬勤、求是创新</div>
                </div>
            </div>
        </div>

        <div className="contact-map fxy--center">
            <div className="relative flex contact-map-center">
                <div className="contact-map" id='mapDom'></div>


                <div className="absolute flex mpa-bttom">
                    <div className={`flex-1 mpa-btm-box ${mapIndex === 1 ? 'box-atv' : null}`} onClick={() => mapClick(1)}>
                        <div className="box-header fx--between">
                            <div className="box-header-title">杭州金智塔科技有限公司</div>
                            <img className='box-header-icon' src={require('@@/assets/img/about/icon.png')} alt="" />
                        </div>
                        <div className="box-content flex">
                            {
                                mapIndex === 1 && <img src={require('@@/assets/img/about/dz-img.png')} className="box-cont-icon" />
                            }
                            {
                                mapIndex !== 1 && <img src={require('@@/assets/img/about/dzbf-gb.png')} className="box-cont-icon" />
                            }

                            <div className="box-cont-txt">杭州市西湖区天堂软件园D幢11楼C、D座</div>
                        </div>
                        <div className="box-content flex">
                            {
                                mapIndex === 1 && <img src={require('@@/assets/img/about/yx-img.png')} className="box-cont-icon" />
                            }
                            {
                                mapIndex !== 1 && <img src={require('@@/assets/img/about/yxbf-img.png')} className="box-cont-icon" />
                            }
                            <div className="box-cont-txt">qis@jztdata.com</div>
                        </div>
                        <div className="box-content flex">
                            {
                                mapIndex === 1 && <img src={require('@@/assets/img/about/dh-img.png')} className="box-cont-icon" />
                            }
                            {
                                mapIndex !== 1 && <img src={require('@@/assets/img/about/dhbfimg.png')} className="box-cont-icon" />
                            }
                            <div className="box-cont-txt">0571-87209876</div>
                        </div>
                    </div>


                    <div className={`flex-1 mpa-btm-box ${mapIndex === 2 ? 'box-atv' : null}`} onClick={() => mapClick(2)}>
                        <div className="box-header fx--between">
                            <div className="box-header-title">金智塔(宁波)数字科技有限公司</div>
                            <img className='box-header-icon' src={require('@@/assets/img/about/icon.png')} alt="" />
                        </div>
                        <div className="box-content flex">
                            {
                                mapIndex !== 1 && <img src={require('@@/assets/img/about/dz-img.png')} className="box-cont-icon" />
                            }
                            {
                                mapIndex === 1 && <img src={require('@@/assets/img/about/dzbf-gb.png')} className="box-cont-icon" />
                            }

                            <div className="box-cont-txt">宁波市鄞州区宁波新材料国际创新中心B1幢</div>
                        </div>
                        <div className="box-content flex">
                            {
                                mapIndex !== 1 && <img src={require('@@/assets/img/about/yx-img.png')} className="box-cont-icon" />
                            }
                            {
                                mapIndex === 1 && <img src={require('@@/assets/img/about/yxbf-img.png')} className="box-cont-icon" />
                            }
                            <div className="box-cont-txt">qis@jztdata.com</div>
                        </div>
                        <div className="box-content flex">
                            {
                                mapIndex !== 1 && <img src={require('@@/assets/img/about/dh-img.png')} className="box-cont-icon" />
                            }
                            {
                                mapIndex === 1 && <img src={require('@@/assets/img/about/dhbfimg.png')} className="box-cont-icon" />
                            }
                            <div className="box-cont-txt">0571-87209876</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="online-message fxy--center">
            <div className="flex online-message-center">
                <div className="flex-1 online-message-card">
                    <div className="online-message-card-header ta-c">在线留言</div>
                    <div className="online-message-card-box">
                        <Form {...layout} ref={formRef}>
                            <Row>
                                <Col span={12} style={{ paddingRight: '20px' }}>
                                    <Form.Item label=" " name="clientName" rules={[{ required: true, message: '请输入姓名' }]}>
                                        <Input placeholder='请输入姓名' size='large' />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: '20px' }}>
                                    <Form.Item label=" " name="companyName" rules={[{ required: true, message: '请输入公司名称' }]}>
                                        <Input placeholder='请输入公司名称' size='large' />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingRight: '20px' }}>
                                    <Form.Item label=" " name="phoneNumber" rules={[{ required: true, message: '请输入联系电话' }, { pattern: /^1[3456789]\d{9}$/, message: '请输入正确联系电话' }]}>
                                        <Input placeholder='请输入联系电话' size='large' maxLength={11} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: '20px' }}>
                                    <Form.Item label=" " name="region" rules={[{ required: true, message: '所在区域' }]}>
                                        <Input placeholder='所在区域' size='large' />
                                        {/* <Select size='large' options={[{ value: '1', label: '湖州' }, { value: '2', label: '宁波' }]} placeholder="所在区域" /> */}
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingRight: '20px' }}>
                                    <Form.Item label=" " name="productName" rules={[{ required: true, message: '在意产品' }]}>
                                        <Input placeholder='意向产品' size='large' />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: '30px' }}>
                                    <Form.Item label=" " name="collaborateType">
                                        <Select size='large' options={[{ value: '产品购买', label: '产品购买' }, { value: '渠道合作', label: '渠道合作' }, { value: '数据共享', label: '数据共享' }]} placeholder="合作方式" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} style={{ paddingLeft: '11px' }}>
                                    <Form.Item label="" name="description">
                                        &nbsp;<TextArea placeholder='留言内容' rows={6} size='large' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="save-btn fxy--center">
                        <div className="save fxy--center cup" onClick={saveFrom}>提交</div>
                    </div>
                </div>
            </div>
        </div>

    </>;
};

export default AboutContact;
