import React from 'react';
import './index.scoped.scss';
import { Helmet } from 'react-helmet';
import dataAssetImg from '@@/assets/img/dataAsset1.png';

const DataAssetInfoH5 = () => {
    return (
        <>
            <Helmet>
                <title>湖州市数据资产登记平台</title>
                <meta name="viewport" id="viewport" content="width=device-width,initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            </Helmet>
            <div className="auth-info">
                <img src={dataAssetImg} />
            </div>
        </>
    );
};

export default DataAssetInfoH5;
