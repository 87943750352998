import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    // {
    //     path: '/demo',
    //     name: 'demo',
    //     component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/demo')),
    //     meta: {
    //         title: 'demo',
    //         intercept: intercept.route
    //     }
    // },
    {
        path: '/mainProd',
        name: 'mainProd',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/pc/mainProd')),
        meta: {
            title: '主要产品',
            intercept: intercept.route
        }
    },
    {
        path: '/coreTechnology',
        name: 'coreTechnology',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/pc/coreTechnology')),
        meta: {
            title: '核心技术',
            intercept: intercept.route
        }
    },
    {
        path: '/case',
        name: 'case',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/pc/classicCases')),
        meta: {
            title: '经典案例',
            intercept: intercept.route
        }
    },
    {
        path: '/about',
        name: 'about',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/pc/about')),
        meta: {
            title: '关于我们',
            intercept: intercept.route
        }
    },
    {
        path: '/recruit',
        name: 'recruit',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/pc/about/recruit')),
        meta: {
            title: '加入我们',
            intercept: intercept.route
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/pc/about/contact')),
        meta: {
            title: '联系我们',
            intercept: intercept.route
        }
    },
    {
        path: '/NewsPage',
        name: 'NewsPage',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/pc/newsPage')),
        meta: {
            title: '新闻资讯',
            intercept: intercept.route
        }
    },
    {
        path: '/NewsDetail',
        name: 'NewsDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/pc/newsPage/detail')),
        meta: {
            title: '新闻详情',
            intercept: intercept.route
        }
    },
    {
        path: '/applyTrial',
        name: 'applyTrial',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-demo" */ '@@/views/modules/pc/applyTrial')),
        meta: {
            title: '申请试用',
            intercept: intercept.route
        }
    },
    {
        path: '/dataAssetInfoH5',
        name: 'dataAssetInfoH5',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataAssetInfoH5" */ '@@/views/modules/pc/dataAssetInfoH5')),
        meta: {
            title: '数据资产基础信息',
            intercept: intercept.route
        }
    },
    {
        path: '/dataAsset2InfoH5',
        name: 'dataAsset2InfoH5',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataAsset2InfoH5" */ '@@/views/modules/pc/dataAsset2InfoH5')),
        meta: {
            title: '数据资产基础信息',
            intercept: intercept.route
        }
    }
];

export default _Route;
