import React from 'react';
import './index.scoped.scss';

const HomeMobile = ()=>{
    return <>
        <div className='fosize'>Mobile</div>
    </>;
};

export default HomeMobile;
