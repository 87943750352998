import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UngroupOutlined, FundProjectionScreenOutlined, FileSearchOutlined, ClusterOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Anchor, Affix } from 'antd';
import './index.scoped.scss';
import Common from '@common/utils/common.until';
import ProductPonsultationFoot from '@@/components/ProductConsultation';
import { Helmet } from 'react-helmet';

import zfcj from '@@/assets/img/main-prod/zf-img.png';
import jrcj from '@@/assets/img/main-prod/jr-img.png';
import qycj from '@@/assets/img/main-prod/qy-img.png';
import sjjys from '@@/assets/img/main-prod/sjjy-img.png';
import ylbj from '@@/assets/img/main-prod/yl-img.png';
import sldzsw from '@@/assets/img/main-prod/ls-img.png';


const MainProd = () => {
    const navigate = useLocation();
    type typeface = '1' | '2' | '3' | '4';
    const [type, setType] = useState<typeface>('1');
    const config = {
        '1': 'header-bg1',
        '2': 'header-bg2',
        '3': 'header-bg3',
        '4': 'header-bg4',
    };

    const [prodGoodTabVal, setProdGoodTab] = useState<number>(0);

    const anchorList = [
        {
            key: 'part-1',
            href: '#part-1',
            title: '核心能力',
        },
        {
            key: 'part-2',
            href: '#part-2',
            title: '产品优势',
        },
        {
            key: 'part-3',
            href: '#part-3',
            title: '应用场景',
        },
        {
            key: 'part-4',
            href: '#part-4',
            title: '其他产品',
        },
        {
            key: 'part-5',
            href: '#part-5',
            title: '立即咨询',
        },
    ];

    const [applicationScenarioStr, setApplicationScenarioStr] = useState<string>('政府场景');

    const applicationScenarioList: { [key: string]: string } = {
        '政府场景': zfcj,
        '金融场景': jrcj,
        '企业场景': qycj,
        '数据交易所': sjjys,
        '医疗保健': ylbj,
        '零售和电子商务': sldzsw,
    };


    useEffect(() => {
        console.log(navigate);
        const state = navigate as { hash: string, key: string, pathname: string, search: string, state: { type: string } };
        const strUrl = state.search as typeface;
        const url = strUrl.replace('?', '') as any;

        setType(url);

    }, [navigate.search]);

    const openLink = () => {
        window.location.href = 'applyTrial';
    };

    const applicationScenarioMove = (val: string) => {
        setApplicationScenarioStr(val);
    };

    const openMainProd = (index: string) => {
        window.location.href = `mainProd?${index}`;
    };

    const prodGoodTab = (val: number) => {
        setProdGoodTab(val);
    };

    const AffixClick = (e: any, link: any) => {
        console.log(e, link);
        if (link.title === '立即咨询') {
            window.location.href = 'applyTrial';
            return false;
        }
    };

    return <>

        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>
        {/* 抬头 */}
        <div className='relative main-prod-header fxy--center'>
            <div className={`fxy--center absolute main-prod-header-bg ${config[type]}`}></div>
            <div className='absolute main-prod-header-box'>
                <div className="prod-header-title">
                    {type === '1' && <>「智隐」隐私计算平台</>}
                    {type === '2' && <>「智通」数据要素流通平台</>}
                    {type === '3' && <>「智算」安全计算平台</>}
                    {type === '4' && <>「智学」机器学习平台</>}
                </div>
                <div className="prod-header-txt">
                    {type === '1' &&
                        <>

                            金智塔科技自主研发的隐私计算平台融合了多方安全计算、同态加密、可信执行环境、区块链、 <br />
                            数字水印等软硬结合技术，提供联合建模、联合统计、匿踪查询、在线推理等服务，实现数据<br />
                            “可用不可见”、“用途可控可计量”，赋能数据要素安全高效流通，并促进数据要素的融合<br />
                            创新应用。

                        </>
                    }
                    {type === '2' &&
                        <>
                            综合运用大数据计算、可信环境计算、隐私计算等技术，提供合规数据融合加工、智能数据出域审查、<br />
                            数据产品安全交付、数据流通全程监管、国产信创生态支持等服务，赋能数据产品市场化流通。
                        </>
                    }
                    {type === '3' &&
                        <>
                            提供数据存储隔离、数据加工私密、数据输出留痕等服务，规避原始数据输出风险，<br />并最大限度发挥数据价值。
                            {/* 基于相互独立的云容器底座，构建以零信任为基准的网络安全体系，提供数据存储隔离、数据加工私密、<br />
                                数据输出留痕等服务，以规避原始数据输出风险，并最大限度发挥数据价值。 */}
                        </>
                    }
                    {type === '4' &&
                        <>
                            基于机器学习、深度学习等技术，提供完整的数据预处理、特征工程、模型训练、模型管理等全链路服务，<br />
                            帮助用户更高效、更便捷的创建和管理自主智能模型，促进数据价值的高效挖掘和应用。
                        </>
                    }
                </div>
                <div className="prod-header-btn cup fxy--center" onClick={openLink}>立即体验</div>
            </div>
        </div>
        {/* <div> */}
        <Affix offsetTop={80} >
            <div className="main-prod-anchor fxy--center">
                <div className="main-prod-anchor-box">
                    <Anchor className='anchor-box-deep' onClick={AffixClick} direction="horizontal" items={anchorList} offsetTop={80} />
                </div>
            </div>
        </Affix>

        <div className='core-competencies fxy--center' id='part-1'>
            <div className="dom-box">
                <div className="core-competencies-header fxy--center">核心能力</div>
                <div className='flex core-competencies-box fxy--center'>
                    {type === '1' &&
                        <>
                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><UngroupOutlined /></div>
                                <div className="core-box-title">联合建模</div>
                                <div className="core-box-txt">支持多方数据在不出域的前提下完
                                    成模型训练，覆盖神经网络、决策
                                    树、逻辑回归等主流算法。
                                </div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><FundProjectionScreenOutlined /></div>
                                <div className="core-box-title">联合统计</div>
                                <div className="core-box-txt">支持多方数据在不出域的前提下完
                                    成统计，覆盖中位数、方差、偏离
                                    度等统计学指标。
                                </div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><FileSearchOutlined /></div>
                                <div className="core-box-title">匿踪查询</div>
                                <div className="core-box-txt">数据查询时，保护查询对象的身份
                                    信息不暴露给数源方，例如身份证
                                    号、电话号码、组织机构代码等。
                                </div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><ClusterOutlined /></div>
                                <div className="core-box-title">在线推理</div>
                                <div className="core-box-txt">将训练完成的模型在隔离环境独立
                                    部署，支持千万级高并发、亿级大
                                    数据、毫秒级响应的场景需求。
                                </div>
                            </div>
                        </>
                    }
                    {type === '2' &&
                        <>
                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><UngroupOutlined /></div>
                                <div className="core-box-title">数据产品加工</div>
                                <div className="core-box-txt">提供大数据计算、可信环境计算、隐私计算等多种产品加工形式，全面满足用户不同业务场景需求。</div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><FundProjectionScreenOutlined /></div>
                                <div className="core-box-title">数据产品审查</div>
                                <div className="core-box-txt">应用风险字段识别、文件动态封装、数据相似度匹配等关键技术，达到数据零信任安全标准。</div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><FileSearchOutlined /></div>
                                <div className="core-box-title">数据产品交易</div>
                                <div className="core-box-txt">为公共数据和社会数据提供数据包、数据接口、数据报告、数据模型等多种交易形式。 </div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><ClusterOutlined /></div>
                                <div className="core-box-title">数据流通监管</div>
                                <div className="core-box-txt">实现数据申请、数据导入、数据加工、产品交付、产品交易全流程监管，保障数据产品合规流通。</div>
                            </div>
                        </>
                    }
                    {type === '3' &&
                        <>
                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><UngroupOutlined /></div>
                                <div className="core-box-title">空间隔离</div>
                                <div className="core-box-txt">基于安全容器对数据进行隔离式管理，数据抽取、加工、交付全流程均处于可信环境。</div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><FundProjectionScreenOutlined /></div>
                                <div className="core-box-title">弹性扩容</div>
                                <div className="core-box-txt">支持空间资源的扩容、回收，实时监测空间资源使用情况，即时满足用户对计算资源弹性使用的需求。</div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><FileSearchOutlined /></div>
                                <div className="core-box-title">数据加工</div>
                                <div className="core-box-txt">应用规则引擎、评分卡、机器学习等100余种模型，全面满足智能风控授信、反欺诈等多个业务场景。 </div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><ClusterOutlined /></div>
                                <div className="core-box-title">安全管理</div>
                                <div className="core-box-txt">利用对称和非对称加密算法对数据进行全流程保护；同时内置多种风控规则，多重防范原始数据泄露。</div>
                            </div>
                        </>
                    }
                    {type === '4' &&
                        <>
                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><UngroupOutlined /></div>
                                <div className="core-box-title">数据准备</div>
                                <div className="core-box-txt">平台提供智能化的数据预处理服务，支持“特征分箱”、“WOE编码”、“类别编码”等几十种特征工程算子。</div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><FundProjectionScreenOutlined /></div>
                                <div className="core-box-title">模型训练</div>
                                <div className="core-box-txt">平台内置了“kmeans”、“lgb”、“dnn”等几十个基础模型，通过可视化托拉拽的方式，快速实现深度学习训练。</div>
                            </div>

                            <div className='flex-1 core-box'>
                                <div className="core-box-icon"><FileSearchOutlined /></div>
                                <div className="core-box-title">模型管理</div>
                                <div className="core-box-txt">对模型整个生命周期进行管理，支持模型不断调优、重新部署、下架操作。 </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>


        <div className={`prod-advantage fxy--center ${type === '2' ? 'prod-advantage-bg' : null}`} id='part-2'>
            <div className='flex prod-advantage-center f-fd-c'>
                <div className="prod-advantage-header fxy--center">产品优势</div>

                {
                    type === '1' &&
                    // <div className='flex flex-1 advantage-box'>
                    //     <div className="flex-1 prod-advantage-box">
                    //         <div className="prod-advantage-box-img">
                    //             <img src={require('@@/assets/img/main-prod/prod-icon1.png')} alt="" />
                    //         </div>
                    //         <div className="prod-advantage-box-title">高安全</div>
                    //         <div className="prod-advantage-box-txt">采用区块链、数字水印等多种技术对数据使用的全链路进行安全防护和存证，并支持国产信创，为安全提供了全方位保障。</div>
                    //     </div>
                    //     <div className="flex-1 prod-advantage-box">
                    //         <div className="prod-advantage-box-img">
                    //             <img src={require('@@/assets/img/main-prod/prod-icon2.png')} alt="" />
                    //         </div>
                    //         <div className="prod-advantage-box-title">高性能</div>
                    //         <div className="prod-advantage-box-txt">采用软硬一体分层架构设计，实现超大规模高效率计算，支持10方节点、亿级求交的业务场景。</div>
                    //     </div>
                    //     <div className="flex-1 prod-advantage-box">
                    //         <div className="prod-advantage-box-img">
                    //             <img src={require('@@/assets/img/main-prod/prod-icon3.png')} alt="" />
                    //         </div>
                    //         <div className="prod-advantage-box-title">高扩展</div>
                    //         <div className="prod-advantage-box-txt">采用全对称的分布式架构，可灵活组网无限扩展节点；支持分布式弹性扩容，高效增强算力，满足用户业务快速发展需求。</div>
                    //     </div>
                    //     <div className="flex-1 prod-advantage-box">
                    //         <div className="prod-advantage-box-img">
                    //             <img src={require('@@/assets/img/main-prod/prod-icon4.png')} alt="" />
                    //         </div>
                    //         <div className="prod-advantage-box-title">高互通</div>
                    //         <div className="prod-advantage-box-txt">以算法模块化、插件化的形式接入不同平台，实现数据、计算的互联互通。</div>
                    //     </div>
                    // </div>
                    <div className='flex flex-1 advantage-box'>

                        <div className={`prod-advantage-box ${prodGoodTabVal === 0 ? 'box-hidden' : ''}`} onMouseMove={() => prodGoodTab(0)}>
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon1.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">高安全</div>
                            <div className="prod-advantage-box-txt opa0">基于多方安全计算、同态加密、可信执行环境、区块链、数字水印等技术对数据使用的全链路进行安全防护和存证，支持国产信创全栈适配，为数据的安全使用提供全方位保障。</div>
                        </div>

                        <div className={`prod-advantage-box ${prodGoodTabVal === 1 ? 'box-hidden' : ''}`} onMouseMove={() => prodGoodTab(1)}>
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon1.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">高可用</div>
                            <div className="prod-advantage-box-txt opa0">采用算法容器化设计，支持集群化部署、弹性扩容；具备任务自动容错、灾难多活、多维度监控预警等系统稳定性功能，拥有工业级安全生产能力。</div>
                        </div>

                        <div className={`prod-advantage-box ${prodGoodTabVal === 2 ? 'box-hidden' : ''}`} onMouseMove={() => prodGoodTab(2)}>
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon2.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">高性能</div>
                            <div className="prod-advantage-box-txt opa0">具备大规模数据分布式计算能力，满足亿级及以上数据规模使用需求；在联合建模、联合统计、匿踪查询等领域性能处于行业头部水平。</div>
                        </div>

                        <div className={`prod-advantage-box ${prodGoodTabVal === 3 ? 'box-hidden' : ''}`} onMouseMove={() => prodGoodTab(3)}>
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon3.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">高互联</div>
                            <div className="prod-advantage-box-txt opa0">支持同源互联、黑盒互联等形式接入异构隐私计算平台，实现数据的大流通和大应用。</div>
                        </div>

                        <div className={`prod-advantage-box ${prodGoodTabVal === 4 ? 'box-hidden' : ''}`} onMouseMove={() => prodGoodTab(4)}>
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon4.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">高扩展</div>
                            <div className="prod-advantage-box-txt opa0">采用全对称的分布式架构，可根据需求灵活组网，扩展合作节点，满足用户业务快速发展需求；支持算法热插拔、算法多版本兼容，解决多用户业务合作时兼容性问题。</div>
                        </div>
                    </div>
                }

                {
                    type === '2' &&
                    <div className='flex flex-1 advantage-box1'>
                        <div className='relative flex-1 advantage-box1-card box1-card-bg1'>
                            <img className='absolute box1-card-img' src={require('../../../../assets/img/main-prod/jflh.png')} alt="" />
                            <div className='absolute box1-card-content'>
                                <div className='box1-card-num'>01</div>
                                <div className='box1-card-title'>交付灵活</div>
                                <div className='box1-card-txt'>支持数据包、数据接口、数据报告等标准产品的交付，同时提供个性化数据产品定制。</div>
                            </div>
                        </div>
                        <div className='relative flex-1 advantage-box1-card box1-card-bg2'>
                            <img className='absolute box1-card-img' src={require('../../../../assets/img/main-prod/spdg.png')} alt="" />
                            <div className='absolute box1-card-content'>
                                <div className='box1-card-num'>02</div>
                                <div className='box1-card-title'>适配度高</div>
                                <div className='box1-card-txt'>系统能力可插拔，支持交易和交付分离、交易交付合一的模式。</div>
                            </div>
                        </div>
                        <div className='relative flex-1 advantage-box1-card box1-card-bg3'>
                            <img className='absolute box1-card-img' src={require('../../../../assets/img/main-prod/aqxg.png')} alt="" />
                            <div className='absolute box1-card-content'>
                                <div className='box1-card-num'>03</div>
                                <div className='box1-card-title'>安全性高</div>
                                <div className='box1-card-txt'>平台底层融合了隐私计算和可信环境计算两种技术能力，建立了完善的数据确权、交易、交付监管体系，保证了数据流通安全合规。</div>
                            </div>
                        </div>
                    </div>
                }

                {
                    type === '3' &&
                    <div className='flex flex-1 advantage-box'>
                        <div className="flex-1 prod-advantage-box">
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon1.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">高防护</div>
                            <div className="prod-advantage-box-txt">采用身份安全、边界安全、访问者网络安全、开放域网络安全等措施进行全方位安全加固，有效杜绝各类攻击。</div>
                        </div>
                        <div className="flex-1 prod-advantage-box">
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon2.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">高私密</div>
                            <div className="prod-advantage-box-txt">上传平台的私有数据皆为密态存储，且内部的数据加工过程严格保密，加工逻辑仅存在于独立空间。</div>
                        </div>
                        <div className="flex-1 prod-advantage-box">
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon3.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">强监管</div>
                            <div className="prod-advantage-box-txt">平台输出的任何数据都将进行逆推检验，即时监测违规加工行为；数据产品交付全链路留痕，可追踪可追责。</div>
                        </div>
                        <div className="flex-1 prod-advantage-box">
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon4.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">高弹性</div>
                            <div className="prod-advantage-box-txt">支持弹性扩容，可满足数据计算量暴增的突发需求，亦可适应业务量逐年增长、场景逐年丰富的现实需要。</div>
                        </div>
                    </div>
                }

                {
                    type === '4' &&
                    <div className='flex flex-1 advantage-box'>
                        <div className="flex-1 prod-advantage-box">
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon1.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">低门槛</div>
                            <div className="prod-advantage-box-txt">平台将建模过程中“重、难”点做成标准化组件，开箱即用，零基础3步即可构建自主智能模型。</div>
                        </div>
                        <div className="flex-1 prod-advantage-box">
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon2.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">高效率</div>
                            <div className="prod-advantage-box-txt">运用贝叶斯优化的调参方法，自动匹配、优化参数，快速完成模型训练。</div>
                        </div>
                        <div className="flex-1 prod-advantage-box">
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon3.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">一站式</div>
                            <div className="prod-advantage-box-txt">平台提供了数据处理、特征工程、模型训练、模型决策等全链路智能建模服务。</div>
                        </div>
                        <div className="flex-1 prod-advantage-box">
                            <div className="prod-advantage-box-img">
                                <img src={require('@@/assets/img/main-prod/prod-icon4.png')} alt="" />
                            </div>
                            <div className="prod-advantage-box-title">全兼容</div>
                            <div className="prod-advantage-box-txt">平台可与外部各业务系统进行完美扩展与兼容，帮助用户节约成本。</div>
                        </div>
                    </div>
                }
            </div>
        </div>


        <div className='application-scenario fxy--center' id='part-3'>
            <div className="flex application-scenario-center f-fd-c">
                <div className="application-scenario-title fxy--center">应用场景</div>
                <div className="flex flex-1 application-scenario-box">
                    <div className="flex scenario-box-menu f-fd-c f-ac-sb">
                        {
                            type === '1' && <>
                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('政府场景')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>政府场景</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">打破政府部门间数据孤岛，助力协同高效数字政府建设。</div>
                                </div>

                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('金融场景')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>金融场景</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">合规融合金融机构内外部数据，赋能金融机构数字化转型。</div>
                                </div>

                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('企业场景')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>企业场景</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">打破企业内外部间的数据壁垒，安全合规实现数据价值共享。</div>
                                </div>
                            </>
                        }
                        {
                            type === '2' && <>
                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('政府场景')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>政府场景</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">监督数据合规流转，实现公共数据安全运营。</div>
                                </div>

                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('企业场景')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>企业场景</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">内部数据加工流程打造，保障对外赋能合规可控。</div>
                                </div>

                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('数据交易所')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>数据交易所</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">数据产品生产安全可控，助力实现前店后厂模式。</div>
                                </div>
                            </>
                        }
                        {
                            type === '3' && <>
                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('政府场景')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>政府场景</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">计算环境隔离，赋能公共数据运营。</div>
                                </div>

                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('企业场景')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>企业场景</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">数据安全加工，盘活数据资产。</div>
                                </div>

                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('数据交易所')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>数据交易所</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">分配加工空间，支撑数据产品交付。</div>
                                </div>
                            </>
                        }
                        {
                            type === '4' && <>
                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('金融场景')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>金融场景</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">风险评估、信用评分、欺诈检测等。</div>
                                </div>

                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('医疗保健')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>医疗保健</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">医学影像分析、疾病诊断与预测、药物研发等。</div>
                                </div>

                                <div className="flex-1 box-menu-card" onMouseMove={() => applicationScenarioMove('零售和电子商务')}>
                                    <div className="box-menu-card-header fx--between-center">
                                        <div className='box-menu-card-title'>零售和电子商务</div>
                                        {/* <div className='box-menu-card-icon'><ArrowRightOutlined /></div> */}
                                    </div>
                                    <div className="box-menu--txt">个性化推荐、库存管理、价格优化等。</div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='flex-1 scenario-box-content fxy--center'>
                        <div className='flex-1 scenario-box-content-img' style={{ backgroundImage: `url(${applicationScenarioList[applicationScenarioStr]})` }} />
                    </div>
                </div>
            </div>
        </div>

        <div className='other-prod fxy--center' id='part-4'>
            <div className="flex other-prod-center f-fd-c">
                <div className="other-prod-header fxy--center">其他产品</div>
                <div className='flex flex-1 other-prod-card'>
                    {
                        type !== '1' && <>
                            <div className='other-prod-card-box' onClick={() => openMainProd('1')}>
                                <div className='other-prod-card-box-header fx--between-center f-ai-c'>
                                    <div className='box-header-title'>「智隐」 隐私计算平台</div>
                                </div>
                                <div className="other-prod-card-box-content">打破数据孤岛，实现数据可用不可见</div>
                                <div className='box-header-icon'><ArrowRightOutlined /></div>
                            </div>
                        </>
                    }
                    {
                        type !== '2' && <>
                            <div className='other-prod-card-box' onClick={() => openMainProd('2')}>
                                <div className='other-prod-card-box-header fx--between-center f-ai-c'>
                                    <div className='box-header-title'>「智通」 数据要素流通平台</div>
                                </div>
                                <div className="other-prod-card-box-content">助力数据合规流通，激发数据价值</div>
                                <div className='box-header-icon'><ArrowRightOutlined /></div>

                            </div>
                        </>
                    }
                    {
                        type !== '3' && <>
                            <div className='other-prod-card-box' onClick={() => openMainProd('3')}>
                                <div className='other-prod-card-box-header fx--between-center f-ai-c'>
                                    <div className='box-header-title'>「智算」 安全计算平台</div>
                                </div>
                                <div className="other-prod-card-box-content">提供数据加工安全环境，深度挖掘数据价值</div>
                                <div className='box-header-icon'><ArrowRightOutlined /></div>
                            </div>
                        </>
                    }
                    {
                        type !== '4' && <>
                            <div className='other-prod-card-box' onClick={() => openMainProd('4')}>
                                <div className='other-prod-card-box-header fx--between-center f-ai-c'>
                                    <div className='box-header-title'>「智学」 机器学习平台</div>
                                </div>
                                <div className="other-prod-card-box-content">高效构建机器学习模型，赋能智能化应用</div>
                                <div className='box-header-icon'><ArrowRightOutlined /></div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>

        {/* 使用 */}
        <div id='part-5'>
            <ProductPonsultationFoot />
        </div>
        {/* </div> */}


    </>;
};

export default MainProd;
