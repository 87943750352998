import axios from 'axios';
import React from 'react';
import { host } from '@@/config/host';

type IMethod = 'GET' | 'PUT' | 'DELETE' | 'POST';

interface ICommonOption {
    [key: string]: any
}

const HttpService = (
    method: IMethod,
    url: string,
    data: ICommonOption = {},
    headerMaps?: ICommonOption,
    otherParams?: ICommonOption
): Promise<any> => {

    const requestAddress = url.search('http') !== -1 ? url : `${host.SERVER_BASE_API}` + url;
    const requestData = data;

    return new Promise((resolve, reject) => {
        const axiosSendConfig: any = {
            method: method,
            withCredentials: false,
            url: requestAddress,
            headers: { ...headerMaps } || {},
            timeout: otherParams && otherParams.timeout ? otherParams.timeout : 30000
        };

        if (method === 'GET') {
            axiosSendConfig.params = requestData;
        } else {
            axiosSendConfig.data = JSON.stringify(requestData);
            axiosSendConfig.headers['content-type'] = 'application/json';
        }

        axios(axiosSendConfig).then((response) => {
            const { code = '', data = {}, msg = '' } = response.data;

            if (code === 401 || code === 10005) {
                return false;
            }

            if (code === 200) {
                resolve([null, data || {}]);
                return;
            }

            resolve([{ code, msg }, data]);
        }).catch((err) => {
            console.warn('err:', err);
            resolve([{}, null]);
        });
    });
};

export { HttpService };
