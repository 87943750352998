import React, { useRef, useState, useEffect } from 'react';
import { RightOutlined, CheckOutlined, ArrowRightOutlined, LeftCircleOutlined, RightCircleOutlined, LeftOutlined } from '@ant-design/icons';
import './index.scoped.scss';
import SwiperCore, { Autoplay, Navigation, EffectCoverflow, SwiperOptions, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory } from 'react-router-dom';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Autoplay, Navigation, EffectCoverflow, EffectFade, Pagination]);

import gou from '../../../assets/img/home/g-img.png';
import dian from '../../../assets/img/home/d-img.png';
import mainZy from '../../../assets/img/home/an-img.png';
import banner1 from '../../../assets/img/home/banner1.jpg';
import banner2 from '../../../assets/img/home/banner2.jpg';
import banner3 from '../../../assets/img/home/banner3.png';
import homeBannerIcon from '../../../assets/img/home/homeBannerIcon.png';


import sthzhbImg1 from '../../../assets/img/home/logo_img.png';
import sthzhbImg2 from '../../../assets/img/home/xz.png';
import sthzhbImg3 from '../../../assets/img/home/qiu.png';


import ProductPonsultationFoot from '@@/components/ProductConsultation';
import { HttpService } from '@@/services/http.service';
import { Helmet } from 'react-helmet';
import FragmentBanner from './banner';
import './banner.css';

const Homepc = () => {
    const history = useHistory();

    const [newsList, setNewsList] = useState<any>([]);

    const [honorList, setHonorList] = useState<any>([
        {
            title: '数字中国建设峰会 | 金智塔科技首席科学家郑小林教授指出数据要素战略是应对大模型挑战的重要举措！',
            digest: '金智塔科技创始人兼首席科学家郑小林教授受邀出席“共建数据要素产业生态高峰论坛”，并作“隐私计算赋能数据要素合规流通”主旨演讲，共话数据要素市场培育与发展。',
            id: '150',
        },
        {
            title: '数博会载誉而归｜金智塔科技荣膺三大奖项',
            digest: '2023数博会由国家发展和改革委员会、工业和信息化部、国家互联网信息办公室和贵州省人民政府共同主办，作为数据要素合规流通与应用领军企业，金智塔科技荣膺“数博会”数据场景应用创新大赛三等奖、“2023数据交易十大优秀案例”奖及“2023数据安全流通创新案例”奖。',
            id: '153',
        },
        {
            title: '金智塔案例入选 IDC《 隐私计算最佳实践与探索》报告',
            digest: '近日，国际权威咨询机构IDC正式发布了《IDC PeerScape: 隐私计算最佳实践与探索》报告。“金智塔隐私计算安全融合政务数据赋能浙商银行智能风控”项目被作为最佳案例入选该报告，为更多用户构建技术架构、推动技术落地、实现业务升级提供参考。',
            id: '146',
        },
        // {
        //     title: '金智塔科技参与的又一国家重点研发计划项目启动',
        //     digest: '近日，由浙江大学牵头，浙江大学副校长、浙江大学长三角一体化发展研究中心主任黄先海教授任项目负责人，联合中国移动、浙江信产、同花顺、浙大滨江院、金智塔科技等9家行业头部单位共同实施的十四五国家重点研发计划“文化科技与现代服务业”重点专项“面向共同富裕的公共服务普惠供给技术及应用”项目启动暨实施方案咨询会顺利召开。金智塔科技CTO陈超超博士受邀出席',
        //     id: '143',
        // },
    ]);

    const [honorInfo, setHonorInfo] = useState<{ name: string, content: string }>({ name: '', content: '' });

    useEffect(() => {
        getHomeNews();
        // getIndexHonor();
        getHonor();

        setBannerDom();
    }, []);

    // 获取首页新闻列表
    const getHomeNews = async () => {
        const [err, res] = await HttpService('POST', '/news/getIndexNews', {});
        if (res) {
            setNewsList(res.newsList);
        }
    };

    // 获取首页荣誉列表
    const getIndexHonor = async () => {
        const [err, res] = await HttpService('POST', '/news/getIndexHonor', {});
        if (res) {
            setHonorList(res.newsList);
            // setNewsList(res.newsList);
        }
    };

    // 获取首页荣誉
    const getHonor = async () => {
        const [err, res] = await HttpService('POST', '/honor/getIndex', {});
        console.log('res.getIndexHonor:::', res);
        if (res) {
            setHonorInfo(res);
            // setNewsList(res.newsList);
        }
    };

    // 主要产品tab切换
    const [mainProductTabVal, setMainProductTabVal] = useState<number>(1);

    // 典型案例 轮播图的按钮下标
    const [exampleBoxBtn, setExampleBoxBtn] = useState<number>(1);
    const exampleSwiperRef = useRef<any>(null);

    const newsRemarkRef = useRef<any>(null);

    // 主要产品tab切换
    const mainProductTabValMouseMove = (val: number) => {
        console.log(val);
        setMainProductTabVal(val);
    };

    // 经典案例 按钮
    const exampleBtnhover = (val: number) => {
        if (val !== exampleBoxBtn) {
            setExampleBoxBtn(val - 1);
            if (exampleSwiperRef.current) {
                exampleSwiperRef.current.slideTo(val - 1);
            }
        }
    };

    // 典型案例 轮播图change事件
    const exampleSwiperChange = (val: any) => {
        setExampleBoxBtn(val.activeIndex + 1);
    };

    const newsRemarkLeftClick = () => {
        console.log('-----1');
        if (newsRemarkRef.current) {
            newsRemarkRef.current.slidePrev();
        }
    };
    const newsRemarkrightClick = () => {
        console.log('-----2');

        if (newsRemarkRef.current) {
            newsRemarkRef.current.slideNext();
        }
    };

    const goLink = (str: string, type: string) => {
        history.push({ pathname: str, search: type });
    };

    // 最新荣誉-查看详情
    const openNewsDetail = (id: any) => {
        history.push({ pathname: '/NewsDetail', search: id });
    };


    const mainProductDetail = (key: string) => {
        history.push({ pathname: '/mainProd', search: key });
    };

    const bannerSwiperRef = useRef<any>();

    const bannerLeftClick = () => {
        if (bannerSwiperRef.current) {
            bannerSwiperRef.current.slidePrev();
        }
    };
    const bannerRightClick = () => {
        if (bannerSwiperRef.current) {
            bannerSwiperRef.current.slideNext();
        }
    };


    const setBannerDom = () => {
        new FragmentBanner({
            container: '#banner2', // 选择容器 必选
            imgs: [banner1, banner2, banner3], // 图片集合
            fnTime: 10000
        });
    };

    return <div className='flex f-fd-c fxy--center'>
        {/* banner 开始 */}
        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>
        <div className='flex page-banner f-fd-c'>
            <div className="relative page-switch">

                {/* 淡入淡出轮播图
                <Swiper loop={true} autoplay={{ delay: 3000, stopOnLastSlide: false, disableOnInteraction: true }} effect="fade" onSwiper={(swiper) => (bannerSwiperRef.current = swiper)}>
                    <SwiperSlide className='ta-c'>
                        <img className='switch-img' src={banner2} alt="" />
                    </SwiperSlide>
                    <SwiperSlide className='ta-c'>
                        <img className='switch-img' src={banner3} alt="" />
                    </SwiperSlide>
                </Swiper>

                <div className="absolute page-switch-left cup" onClick={bannerLeftClick}><LeftCircleOutlined /></div>
                <div className="absolute page-switch-right cup" onClick={bannerRightClick}><RightCircleOutlined /></div> */}


                <div className="banner" id="banner2">
                    <div className="banner-view"></div>
                    <div className="banner-btn"></div>
                    <div className="banner-number" style={{ display: 'none' }}></div>
                    <div className="banner-progres"></div>
                </div>


                <div className="absolute banner-case2 fxy--center">
                    <div className="flex banner-case2-center">
                        <div className="flex flex-1">

                            <div className="flex banner-case2-box transition-hover-down cup f-fd-c f-jc-c " onClick={() => openNewsDetail('158')}>
                                <div className="flex banner-case2-box-header f-ai-c">
                                    <img className='banner-case2-box-header-icon' src={homeBannerIcon} alt="" />
                                    <div className='banner-case2-box-header-txt' >全国一等奖</div>
                                </div>
                                <div className="banner-case2-txt">中国信通院隐私计算大赛</div>
                            </div>

                            <div className="flex banner-case2-box transition-hover-down cup f-fd-c f-jc-c" onClick={() => openNewsDetail('168')}>
                                {/* <div className="flex banner-case2-box transition-hover-down cup f-fd-c f-jc-c" onClick={() => mainProductDetail('2')}> */}
                                <div className="flex banner-case2-box-header f-ai-c">
                                    <img className='banner-case2-box-header-icon' src={homeBannerIcon} alt="" />
                                    <div className='banner-case2-box-header-txt' >重磅发布</div>
                                </div>
                                <div className="banner-case2-txt">“智通”数据要素流通平台V1</div>
                            </div>

                            <div className="flex banner-case2-box transition-hover-down cup f-fd-c f-jc-c" onClick={() => openNewsDetail('143')}>
                                <div className="flex banner-case2-box-header f-ai-c">
                                    <img className='banner-case2-box-header-icon' src={homeBannerIcon} alt="" />
                                    <div className='banner-case2-box-header-txt' >攻坚关键技术</div>
                                </div>
                                <div className="banner-case2-txt">参与又一国家重点研发项目启动</div>
                            </div>

                            <div className="flex banner-case2-box transition-hover-down cup f-fd-c f-jc-c" onClick={() => openNewsDetail('169')}>
                                <div className="flex banner-case2-box-header f-ai-c">
                                    <img className='banner-case2-box-header-icon' src={homeBannerIcon} alt="" />
                                    <div className='banner-case2-box-header-txt' >金融街论坛年会</div>
                                </div>
                                <div className="banner-case2-txt">金融科技伦理治理新观点</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className=" banner-case1 fxy--center">
                <div className="flex banner-case1-center">
                    <div className="flex flex-1">
                        <div className="banner-case-card fxy--center f-fd-c cup" onClick={()=>openNewsDetail('158')}>
                            <div className="banner-case-card-header">全国一等奖</div>
                            <div className="banner-case-card-txt ta-c">中国信通院隐私计算大赛</div>
                        </div>
                        <div className="banner-case-card fxy--center f-fd-c cup" onClick={()=>mainProductDetail('2')}>
                            <div className="banner-case-card-header">重磅发布</div>
                            <div className="banner-case-card-txt ta-c">“智通”数据要素流通平台V1.0</div>
                        </div>
                        <div className="banner-case-card fxy--center f-fd-c cup" onClick={()=>openNewsDetail('143')}>
                            <div className="banner-case-card-header">攻坚关键技术</div>
                            <div className="banner-case-card-txt ta-c">承担国家重点研发项目启动</div>
                        </div>
                        <div className="banner-case-card fxy--center f-fd-c cup" onClick={()=>openNewsDetail('153')}>
                            <div className="banner-case-card-header">数博会载誉而归</div>
                            <div className="banner-case-card-txt ta-c">荣获三大奖项</div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <div className='absolute flex banner-case'>

                <div className='flex banner-case-box f-jc-c f-ai-fe'>
                    <div className="flex f-fd-c f-jc-fe case-box1">
                        <div className='case-box1-title fxy--center'>星河杯</div>
                        <div className='case-box1-txt ta-c'>「 全国隐私计算大赛获得<br />一等奖 」</div>
                    </div>
                </div>
                {
                    honorList.map((item: any, index: number) =>

                        <div className="flex banner-case-box pa-s24 transition-hover-up f-jc-sb f-fd-c" key={index}>
                            <div className='case-box-header'>{item.title}</div>
                            <div className='case-box-content'>{item.digest}</div>
                            <div className='case-box-link' onClick={() => openNewsDetail((item.id).toString())}><span>查看详情</span><RightOutlined /></div>
                        </div>
                    )
                }
            </div> */}
        </div>

        {/* 主要产品 */}
        <div className="main-product fxy--center">
            <div className="product">
                <div className='product-header fxy--center'>主要产品</div>
                <div className='product-content ta-c'>坚持自主创新研发，以「技术+场景」双轮驱动产品升级与迭代，打造契合市场需求的数据合规流通与应用产品</div>
                <div className="flex product-btn-list">
                    <div className={`relative product-btn  ${mainProductTabVal === 1 ? 'btn-active' : ''}`} onMouseMove={() => mainProductTabValMouseMove(1)} >
                        <div className={`absolute product-btn-txt ${mainProductTabVal === 1 ? 'wr' : ''}`}>智隐</div>
                        <img src={mainZy} className={`absolute product-btn-img ${mainProductTabVal === 1 ? 'opacity' : ''} `} alt="" />
                    </div>
                    <div className={`relative product-btn  ${mainProductTabVal === 2 ? 'btn-active' : ''}`} onMouseMove={() => mainProductTabValMouseMove(2)} >
                        <div className={`absolute product-btn-txt ${mainProductTabVal === 2 ? 'wr' : ''}`}>智通</div>
                        <img src={mainZy} className={`absolute product-btn-img ${mainProductTabVal === 2 ? 'opacity' : ''} `} alt="" />
                    </div>
                    <div className={`relative product-btn  ${mainProductTabVal === 3 ? 'btn-active' : ''}`} onMouseMove={() => mainProductTabValMouseMove(3)}>
                        <div className={`absolute product-btn-txt ${mainProductTabVal === 3 ? 'wr' : ''}`}>智算</div>
                        <img src={mainZy} className={`absolute product-btn-img ${mainProductTabVal === 3 ? 'opacity' : ''} `} alt="" />
                    </div>
                    <div className={`relative product-btn  ${mainProductTabVal === 4 ? 'btn-active' : ''}`} onMouseMove={() => mainProductTabValMouseMove(4)}>
                        {/* <div className='relative product-btn zx fxy--center' onMouseMove={() => mainProductTabValMouseMove(4)} onClick={() => mainProductTabValMouseMove(4)}> */}
                        <div className={`absolute product-btn-txt ${mainProductTabVal === 4 ? 'wr' : ''}`}>智学</div>
                        <img src={mainZy} className={`absolute product-btn-img ${mainProductTabVal === 4 ? 'opacity' : ''} `} alt="" />
                    </div>
                </div>
            </div>
        </div>

        {/* 主要产品-切换 */}
        <div className={`main-product-box fxy--center box-bg${mainProductTabVal}`}>
            <div className="product-box">
                <div className="product-box-header">
                    <div className="flex product-box-header-title f-ai-c">
                        {mainProductTabVal === 1 && <>智隐 <img src={dian} className='product-box-header-title-dian' /> 隐私计算平台</>}
                        {mainProductTabVal === 2 && <>智通 <img src={dian} className='product-box-header-title-dian' /> 数据流通平台</>}
                        {mainProductTabVal === 3 && <>智算 <img src={dian} className='product-box-header-title-dian' /> 安全计算平台</>}
                        {mainProductTabVal === 4 && <>智学 <img src={dian} className='product-box-header-title-dian' /> 机器学习平台</>}
                    </div>
                    <div className="product-box-header-txt">
                        {mainProductTabVal === 1 && <>实现数据“可用不可见”、“用途可控可计量”，赋能数据要素安全高效流通，并促进数据要素的<br />融合创新应用。</>}
                        {mainProductTabVal === 2 && <>提供合规数据融合加工、智能数据出域审查、数据产品安全交付、数据流通全程监管等服务，<br />赋能数据产品市场化流通。</>}
                        {mainProductTabVal === 3 && <>提供数据存储隔离、数据加工私密、数据输出留痕等服务，以规避原始数据输出风险，并最大<br />限度发挥数据价值。</>}
                        {mainProductTabVal === 4 && <>提供完整的数据预处理、特征工程、模型训练、模型管理等全链路服务，促进数据价值的高效<br />挖掘和应用。</>}
                    </div>
                </div>
                <div className="product-box-good">
                    <div className='product-box-good-title'>产品优势</div>
                    <div className="flex good-list f-ai-c">
                        {
                            mainProductTabVal === 1 && <>
                                <div className='flex good-list-box'>
                                    <div className="good-list-box-remark fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>高安全</span>
                                    </div>
                                    <div className="good-list-box-remark ml-s42 fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>高可用</span>
                                    </div>
                                    <div className="good-list-box-remark ml-s42 fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>高性能</span>
                                    </div>
                                    <div className="good-list-box-remark fxy--center ml-s42"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>高互联</span>
                                    </div>
                                    <div className="good-list-box-remark fxy--center ml-s42"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>高扩展</span>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            mainProductTabVal === 2 && <>
                                <div className='flex good-list-box'>
                                    <div className="good-list-box-remark fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>交付灵活</span>
                                    </div>
                                    <div className="good-list-box-remark ml-s42 fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>适配度高</span>
                                    </div>
                                    <div className="good-list-box-remark ml-s42 fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>安全性高</span>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            mainProductTabVal === 3 && <>
                                <div className='flex good-list-box'>
                                    <div className="good-list-box-remark fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>高防护</span>
                                    </div>
                                    <div className="good-list-box-remark ml-s42 fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>高私密</span>
                                    </div>
                                    <div className="good-list-box-remark ml-s42 fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>强监管</span>
                                    </div>
                                    <div className="good-list-box-remark fxy--center ml-s42"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>高弹性</span>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            mainProductTabVal === 4 && <>
                                <div className='flex good-list-box'>
                                    <div className="good-list-box-remark fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>低门槛</span>
                                    </div>
                                    <div className="good-list-box-remark ml-s42 fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>高效率</span>
                                    </div>
                                    <div className="good-list-box-remark ml-s42 fxy--center"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>一站式</span>
                                    </div>
                                    <div className="good-list-box-remark fxy--center ml-s42"><img src={gou} className='good-list-box-remark-icon' />
                                        <span className='good-list-box-remark-txt'>全兼容</span>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </div>

                <div className="flex product-box-list">
                    {
                        mainProductTabVal === 1 && <>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">联合建模</div>
                                <div className="box-list-txt">支持多方数据在不出域的前提下完成模型训练，覆盖神经网络、决策树、逻辑回归等主流算法</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">联合统计</div>
                                <div className="box-list-txt">支持多方数据在不出域的前提下完 成统计，覆盖中位数、方差、偏离 度等统计学指标</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">匿踪查询</div>
                                <div className="box-list-txt">数据查询时，保护查询对象的身份信息不暴露给数源方，例如身份证号、电话号码、组织机构代码等</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">在线推理</div>
                                <div className="box-list-txt">将训练完成的模型在隔离环境独立部署，支持千万级高并发、亿级大数据、毫米响应的场景需求</div>
                            </div>
                        </>
                    }
                    {
                        mainProductTabVal === 2 && <>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">数据产品加工</div>
                                <div className="box-list-txt">提供大数据计算、可信环境计算、隐私计算等多种产品加工形式，全面满足用户不同业务场景需求</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">数据产品审查</div>
                                <div className="box-list-txt">应用风险字段识别、文件动态封装、数据相似度匹配等关键技术，达到数据零信任安全标准</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">数据产品交易</div>
                                <div className="box-list-txt">为公共数据和社会数据提供数据包、数据接口、数据报告、数据模型等多种交易形式</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">数据流通监管</div>
                                <div className="box-list-txt">实现数据申请、数据导入、数据加工、产品交付、产品交易全流程监管、保障数据产品合规流通</div>
                            </div>
                        </>
                    }
                    {
                        mainProductTabVal === 3 && <>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">空间隔离</div>
                                <div className="box-list-txt">基于安全容器对数据进行隔离式管理，数据抽取、加工、交付全流程均处于可信环境</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">数据加工</div>
                                <div className="box-list-txt">应用规则引擎、评分卡、机器学习等100余种模型，全面满足智能风控授信、反欺诈等多个业务场景</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">弹性扩容</div>
                                <div className="box-list-txt">支持空间资源的扩容、回收，实时监测空间资源使用情况，即时满足用户对计算资源弹性使用的需求</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">安全管理</div>
                                <div className="box-list-txt">利用对称和非对称加密算法对数据进行全流程保护；同时内置多种风控规则，多重防范原始数据泄露。</div>
                            </div>
                        </>
                    }
                    {
                        mainProductTabVal === 4 && <>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">数据准备</div>
                                <div className="box-list-txt">平台提供智能化的数据预处理服务，支持“特征分箱”、“WOE编码”、“类别编码”等几十种特征工程算子</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">模型管理</div>
                                <div className="box-list-txt">对模型整个生命周期进行管理，支持模型不断调优、重新部署、下架操作</div>
                            </div>
                            <div className="box-list transition-hover-up">
                                <div className="box-list-title">模型训练</div>
                                <div className="box-list-txt">平台内置了“kmeans”、“lgb”、“dnn”等几十个基础模型，通过可视化拖拉拽的方式，快速实现深度学习训练</div>
                            </div>
                            {/* <div className="box-list transition-hover-up">
                                <div className="box-list-title">安全管理</div>
                                <div className="box-list-txt">利用对称和非对称密钥算法进行数据的全程加密；同时内置多种风控规则，多重防范原始数据泄露</div>
                            </div> */}
                        </>
                    }
                </div>

                <div className='flex product-box-good-btn'>
                    <div className='good-detail fxy--center' onClick={() => mainProductDetail((mainProductTabVal).toString())}>查看详情</div>
                </div>
            </div>
        </div>

        {/* 典型案例 */}
        <div className="main-example fxy--center">
            <div className='main-example-box'>
                <div className="main-example-header fxy--center">典型案例</div>
                <div className="main-example-remark fxy--center">联合浙江省大数据局、浙江省统计局、浙江省经信厅、浙商银行、宁波银行、郑州商品交易所、美的集团等行业标杆用户打造了近百个数据合规流通与应用典型案例</div>
                <div className="relative example-box">

                    <Swiper loop={false} autoplay={true} onSwiper={(swiper) => (exampleSwiperRef.current = swiper)}
                        onSlideChange={(swiper) => exampleSwiperChange(swiper)}>
                        <SwiperSlide>
                            <div className="example-box1 box1-bg">
                                <div className='example-box1-title'>政务数据多跨安全共享与创新应用</div>
                                <div className='example-box1-txt'>金智塔科技联合某省统计局、经信厅等单位，应用隐私计算等技术，构建了省市县三级一体化横向、<br />纵向的数据要素流通体系。</div>
                                <div className='example-box1-btn fxy--center' onClick={() => goLink('/case', '1')}>查看详情</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="example-box1 box2-bg">
                                <div className='example-box1-title'>某副省级城市数据要素流通平台建设</div>
                                <div className='example-box1-txt'>金智塔科技应用隐私计算、可信环境计算等技术，为某副省级城市构建了一个安全、合规、可信、<br />高效的数据加工和交付审查环境。</div>
                                <div className='example-box1-btn fxy--center' onClick={() => goLink('/case', '2')}>查看详情</div>
                            </div>
                        </SwiperSlide>

                        {/* <SwiperSlide>
                            <div className="example-box1 box4-bg">
                                <div className='example-box1-title'>合规融合内外部数据助力共同富裕建设</div>
                                <div className='example-box1-txt'>通过破解政府数据孤岛和个人隐私保护难题，实现了共富发展智能监测和政策精准制定、推送。</div>
                                <div className='example-box1-btn fxy--center' onClick={() => goLink('/case', '4')}>查看详情</div>
                            </div>
                        </SwiperSlide> */}
                        <SwiperSlide>
                            <div className="example-box1 box3-bg">
                                <div className='example-box1-title'>安全融合政务数据赋能银行智能风控</div>
                                <div className='example-box1-txt'>金智塔科技联合多家银行，运用隐私计算技术对接了浙江省金融综合服务平台，安全融合政务数据，<br />助力银行智能风控等业务的创新发展</div>
                                <div className='example-box1-btn fxy--center' onClick={() => goLink('/case', '3')}>查看详情</div>
                            </div>
                        </SwiperSlide>
                    </Swiper>

                    {/* 按钮组 */}
                    <div className='absolute flex example-box-btn-list f-ai-fe'>
                        <div className={`example-box-btn cup ${exampleBoxBtn === 1 ? 'example-box-btn-hover' : ''}`} onMouseMove={() => exampleBtnhover(1)}>
                            <div className='example-box-btn-txt'>政务数据多跨安全<br />共享与创新应用</div>
                            <div className="example-box-btn-icon"></div>
                        </div>
                        <div className={`example-box-btn cup ${exampleBoxBtn === 2 ? 'example-box-btn-hover' : ''}`} onMouseMove={() => exampleBtnhover(2)}>
                            <div className='example-box-btn-txt'>某副省级城市数据要素<br />流通平台建设</div>
                            <div className="example-box-btn-icon"></div>
                        </div>
                        {/* <div className={`example-box-btn cup ${exampleBoxBtn === 4 ? 'example-box-btn-hover' : ''}`} onMouseMove={() => exampleBtnhover(4)}>
                            <div className='example-box-btn-txt'>合规融合内外部数据助<br />力共同富裕建设</div>
                            <div className="example-box-btn-icon"></div>
                        </div> */}
                        <div className={`example-box-btn cup ${exampleBoxBtn === 3 ? 'example-box-btn-hover' : ''}`} onMouseMove={() => exampleBtnhover(3)}>
                            <div className='example-box-btn-txt'>安全融合政务数据赋能<br />银行智能风控</div>
                            <div className="example-box-btn-icon"></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        {/* 新闻资讯 */}
        <div className="news-remark fxy--center">
            <div className="news-remark-box">
                <div className="news-remark-title fxy--center">新闻资讯</div>
                <div className="news-remark-txt fxy--center">您的关注与支持，是我们不断向前的动力 <a className='ml-s10' onClick={() => goLink('/NewsPage', '')}>查看全部新闻 <ArrowRightOutlined /></a></div>
                <div className="relative news-remark-swiper">
                    <Swiper loop={true} autoplay={true} slidesPerView={3} onSwiper={(swiper) => (newsRemarkRef.current = swiper)}>
                        {
                            newsList.map((item: any, index: number) =>
                                <SwiperSlide key={index}>
                                    <div key={index} className='flex news-remark-swiper-box f-fd-c cup' onClick={() => goLink('/NewsDetail', (item.id).toString())}>
                                        <img className="new-remark-img" src={item.coverImg} />
                                        <div className="flex-1 new-remark-box-bg">
                                            <div className="new-remark-box-bg-date fx--between-center">
                                                <span>{item.createTime}</span>
                                                <ArrowRightOutlined />
                                            </div>
                                            <div className="new-remark-box-bg-title">{item.title}</div>
                                            <div className="new-remark-box-bg-content">{item.digest}</div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    </Swiper>
                    <div className="news-remark-left cup" onClick={newsRemarkLeftClick}><LeftCircleOutlined /></div>
                    <div className="news-remark-right cup" onClick={newsRemarkrightClick}><RightCircleOutlined /></div>
                </div>
            </div>
        </div>

        {/* 生态伙伴 */}
        <div className="ecological-partner fxy--center">
            <div className="ecological-partner-main">
                <div className="ecological-partner-title fxy--center">生态伙伴</div>
                <div className="ecological-partner-txt fxy--center">深度融合产学研用资源，共同构建数据要素合规流通与应用生态</div>
                <div className='relative ecological-partner-box'>
                    <img className="absolute ecological-partner-box-img" src={sthzhbImg1} />
                    <img className="absolute ecological-partner-box-yuan" src={sthzhbImg2} />
                    <img className="absolute ecological-partner-box-qiu" src={sthzhbImg3} />

                </div>
            </div>
        </div>

        {/* 赋能 */}
        <ProductPonsultationFoot />

    </div>;
};

export default Homepc;
