import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { scrollToTop } from '@@/utils/common.until';
import './index.scoped.scss';


const Navbar = (props: any) => {
    const history = useHistory();
    const path = props.match.path;

    const [obvious, setObvious] = useState(false);

    const [isShow, setIsShow] = useState<string>('');

    const routers = [
        { name: '首页', url: '/' },
        // { name: '核心技术', url: '/coreTechnology' },
        { name: '主要产品', url: '/mainProd', drop: true },
        { name: '典型案例', url: '/case' },
        // { name: '典型案例', url: '/case', drop: true },
        { name: '新闻资讯', url: '/NewsPage' },
        { name: '关于我们', url: '/about', drop: true }
    ];

    const openEject = (val: string) => {
        setIsShow(val);
    };


    useEffect(() => {
        window.addEventListener('scroll', () => {
            setObvious(document.documentElement.scrollTop > 80);
        });
    }, []);

    const goLink = async (str: string, type?: string) => {
        history.push({ pathname: str, search: type });
        await scrollToTop();
    };

    return (
        <div className={`navbar ${(obvious || path !== '/') && 'obvious'}`}>
            <div className="layout flexbt">
                <div className="logo cup" onClick={() => history.push({ pathname: '/' })} />
                <div className="flexbt" style={{ height: '100%' }}>
                    <ul className={`routers flexst ${path === '/' ? 'isHome' : ''}`}>
                        {
                            routers.map((item, index) => (
                                <li key={index} className={`${item.drop === true ? 'drop' : ''}`}
                                    onClick={() => !item.drop ? history.push({ pathname: item.url }) : ''}
                                    onMouseOver={() => openEject(item.name)}>
                                    {item.name} {item.drop && <DownOutlined className='icon' />}
                                </li>
                            ))
                        }


                        <div className={`fxy--center eject `}>
                            <div className="eject-center">
                                <div className="flex-1">
                                    <div className="eject-header">
                                        {
                                            isShow === '主要产品' && '主要产品'
                                        }

                                        {
                                            isShow === '典型案例' && '典型案例'
                                        }

                                        {
                                            isShow === '关于我们' && '关于我们'
                                        }
                                    </div>
                                    <div className="flex eject-card">
                                        {
                                            isShow === '主要产品' && <>
                                                <div className="eject-card-box bg-1 cup" onClick={() => goLink('/mainProd', '1')}>
                                                    <div className='h-c'>智隐隐私计算平台</div>
                                                    <div className="eject-card-box-txt t-c">赋能数据要素安全高效流通</div>
                                                </div>
                                                <div className="eject-card-box cup bg-2" onClick={() => goLink('/mainProd', '2')}>
                                                    <div className='h-c'>智通数据流通平台</div>
                                                    <div className="eject-card-box-txt t-c">赋能数据产品市场化流通</div>
                                                </div>
                                                <div className="eject-card-box cup bg-3" onClick={() => goLink('/mainProd', '3')}>
                                                    <div className='h-c'>智算安全计算平台</div>
                                                    <div className="eject-card-box-txt t-c">最大限度发挥数据价值</div>
                                                </div>
                                                <div className="eject-card-box cup bg-4" onClick={() => goLink('/mainProd', '4')}>
                                                    <div className='h-c'>智学机器学习平台</div>
                                                    <div className="eject-card-box-txt t-c">促进数据价值的高效挖掘和应用</div>
                                                </div>
                                            </>
                                        }

                                        {/* {
                                            isShow === '典型案例' && <>
                                                <div className="eject-card-box cup bg-5" onClick={() => goLink('/case', '1')}>统计微观数据多跨<br />安全共享与创新应用</div>
                                                <div className="eject-card-box cup bg-6" onClick={() => goLink('/case', '2')}>某副省级城市数据<br /> 流通平台</div>
                                                <div className="eject-card-box cup bg-7" onClick={() => goLink('/case', '3')}>合规融合内外部数<br /> 据助力共同富裕建设</div>
                                                <div className="eject-card-box cup bg-8" onClick={() => goLink('/case', '4')}>安全合规融合政务<br /> 数据赋能银行智能风控、</div>
                                            </>
                                        } */}

                                        {
                                            isShow === '关于我们' && <>
                                                <div className="eject-card-box cup bg-9" onClick={() => goLink('/about')}>
                                                    <div className='eject-card-box-title'>公司简介</div>
                                                    <div className="eject-card-box-txt">让数智世界更可信</div>
                                                </div>
                                                <div className="eject-card-box cup bg-10" onClick={() => goLink('/recruit')}>
                                                    <div className='eject-card-box-title'>智领未来，由你定义</div>
                                                    <div className="eject-card-box-txt">欢迎加入金智塔科技</div>
                                                </div>
                                                <div className="eject-card-box cup bg-11" onClick={() => goLink('/contact')}>
                                                    <div className='eject-card-box-title'>联系我们</div>
                                                    <div className="eject-card-box-txt">客户至上、诚信为本、天道酬勤、求是创新</div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>


        </div>
    );
};

export default withRouter(Navbar);
