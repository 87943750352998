import React, { forwardRef, useState, useImperativeHandle } from 'react';
import './index.scoped.scss';

interface IProps {
    show: boolean,
    dialogClose: () => void
}
const DialogDom = ({ show, dialogClose }: IProps, ref: any) => {

    const [isShow, setIsShow] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
        report: async () => {
            init();
        }
    }));


    const init = () => {
        document.body.style.overflowY = 'hidden';
        setIsShow(true);

    };

    const closeDialog = () => {
        document.body.style.overflowY = 'auto';
        setIsShow(false);
        dialogClose();
    };

    const stopClose = (e: any) => {
        e.stopPropagation();
    };

    return <>
        {
            isShow &&
            <div className='relative dialog-dom' onClick={closeDialog}>
                <div className="absolute dialog-close" onClick={closeDialog}>X</div>
                <div className="absolute dialog-main" onClick={stopClose}>
                    <video src='https://jzt-guanwang.oss-cn-hangzhou.aliyuncs.com/jztdata.mp4' width="100%" height="100%" controls autoPlay />
                </div>
            </div>
        }
    </>;
};

export default forwardRef(DialogDom);
