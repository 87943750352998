import React, { useEffect, useState } from 'react';
import { Affix } from 'antd';
import { HomeOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import './index.scoped.scss';
import { HttpService } from '@@/services/http.service';
import { Helmet } from 'react-helmet';


const NewsPageDetail = () => {
    const navigate = useLocation();
    const history = useHistory();

    useEffect(() => {
        console.log(navigate);
        const state = navigate as { hash: string, key: string, pathname: string, search: string, state: { type: string } };
        const strUrl = state.search;
        const url = strUrl.replace('?', '') as any;
        getNewsDetail(url);
        getNewsRecommend();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [navigate.search]);


    const [newsInfo, setNewsInfo] = useState<any>({});
    const [newsRecommend, setNewsRecommend] = useState<any>([]);

    const getNewsDetail = async (id: string) => {
        const [err, res] = await HttpService('POST', '/news/getNews', { id: id });
        if (res) {
            setNewsInfo(res);
        }
    };

    const getNewsRecommend = async () => {
        const [err, res] = await HttpService('POST', '/news/getIndexNews', {});
        if (res) {
            setNewsRecommend(res.newsList);
        }
    };

    const openLink = (type: string) => {
        history.push({ pathname: '/NewsDetail', search: type });
    };


    return <>
        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>
        <div className="news-detail fxy--center">
            <div className="flex news-detail-center">
                <div className='flex-1 mr-s86'>
                    <div className="news-detail-header">{newsInfo.title}</div>
                    <div className="news-detail-header-txt">
                        <div className="news-detail-header-txt-name"><HomeOutlined /><span className='ml-s15'>金智塔</span></div>
                        <div className="news-detail-header-txt-date"><ClockCircleOutlined /><span className='ml-s15'>{newsInfo.createTime}</span></div>
                    </div>
                    {/* 渲染富文本格式 */}
                    <div dangerouslySetInnerHTML={{ __html: newsInfo.content }}></div>
                </div>
                <div className='news-tj'>
                    {/* <Affix offsetTop={80}> */}
                    <div className="news-tj-header">为您推荐</div>
                    {
                        newsRecommend.map((item: any, index: number) =>
                            <div className="news-tj-card" key={index} onClick={() => openLink((item.id).toString())}>
                                <div className="flex news-tj-card-box">
                                    <img className="news-tj-card-box-img" src={item.coverImg} alt="" />
                                    <div className="flex news-tj-card-box-img-center f-fd-c f-jc-sb">
                                        <div className="news-tj-card-box-img-center-title">{item.title}</div>
                                        <div className="news-tj-card-box-img-center-date">{item.createTime}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {/* </Affix> */}
                </div>
            </div>
        </div>
    </>;
};

export default NewsPageDetail;
