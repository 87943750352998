import React, { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import { useHistory } from 'react-router-dom';
import './index.scoped.scss';
import { HttpService } from '@@/services/http.service';
import { Helmet } from 'react-helmet';

const NewsPage = () => {
    const history = useHistory();

    const [newsList, setNewsList] = useState<any>([]);

    const [pagination, setPagination] = useState<{ page: number, pageSize: number, total: number }>({ page: 1, pageSize: 10, total: 0 });

    const openDetail = (id: string) => {
        history.push({ pathname: '/NewsDetail', search: String(id) });
    };


    // 返回到顶部
    const scrollToTop = () => {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, currentScroll - currentScroll / 8);
        }
    };


    useEffect(() => {
        console.log('-----');
        getList();
        scrollToTop();
    }, []);

    const getList = async () => {
        const [err, res] = await HttpService('POST', '/news/getIndexNewsPage', { page: pagination.page, pageSize: pagination.pageSize });
        console.log('res.dataPackage:::', res);
        if (res) {
            pagination.total = res.total;
            setPagination({ ...pagination });
            setNewsList(res.records);
        }
    };

    const pageChange = (page: number, size: number) => {
        pagination.page = page;
        pagination.pageSize = size;
        setPagination({ ...pagination });
        console.log(pagination);
        getList();

    };
    const setSplitDay = (str: string) => {
        if (str) {
            const dateTime = str.split(' ');
            const dayStr = dateTime[0].split('-');
            const day = dayStr[dayStr.length - 1];
            return day;
        } else {
            return '';
        }

    };

    const setSplitYearDay = (str: string) => {
        if (str) {
            const dateTime = str.split(' ');
            const dayStr = dateTime[0].split('-');
            return `${dayStr[0]}-${dayStr[1]}`;
        } else {
            return '';
        }
    };

    return <>
        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>
        <div className="news fxy--center">
            <div className="flex news-center">
                <div className="flex-1">
                    <div className="news-header">金智塔动态</div>
                    <div className="news-title">金智塔最新动态，精彩内容尽收眼底，您的关注与支持，是我们不断前进的动力！</div>
                </div>
            </div>
        </div>
        {/* <div className="news">
            <img className='news-header' src={require('@@/assets/img/news/xwzx-bg.png')} alt="" />
        </div> */}
        <div className="news-content fxy--center">
            <div className="news-center">
                {
                    newsList.map((item: any, index: number) =>
                        <div className="news-card" key={index} onClick={() => openDetail(item.id)}>
                            <img className="new-card-img" src={item.coverImg} alt="" />
                            <div className="new-card-box">
                                <div className="new-card-box-date">
                                    <div className="new-card-box-date-day">{setSplitDay(item.createTime)}</div>
                                    <div className="new-card-box-date-year">{setSplitYearDay(item.createTime)}</div>
                                </div>
                                <div className="flex f-fd-c new-card-box-center">
                                    <div className="new-card-box-title">{item.title}</div>
                                    <div className="new-card-box-txt">{item.digest}</div>
                                    <div className="new-card-box-btn" >查看详情</div>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="page fxy--center">
                    <Pagination defaultCurrent={pagination.page} onChange={pageChange} total={pagination.total} />
                </div>
            </div>
        </div>
    </>;
};

export default NewsPage;

