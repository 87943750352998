import React, { useRef } from 'react';
import './index.scoped.scss';
import { Form, Input, message } from 'antd';
const { TextArea } = Input;
import { HttpService } from '@@/services/http.service';
import { Helmet } from 'react-helmet';

const ApplyTrialDom = () => {

    const formRef = useRef<any>(null);

    const saveFromAjax = async (row: any) => {
        const [err, res] = await HttpService('POST', 'productProbation/insert', row);
        console.log('res.dataPackage:::', res);

        message.success('提交成功');
        formRef.current.resetFields();
    };

    const saveFrom = () => {
        formRef.current.validateFields().then((res: any) => {
            saveFromAjax(res);
        }).catch((err: any) => { });
    };


    return <div className='page fxy--center'>
        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>
        <div className="page-box">
            <div className="page-box-header">金智塔产品申请试用</div>
            <Form layout="vertical" ref={formRef}>
                <Form.Item label="姓名" name="clientName" rules={[{ required: true, message: '请输入姓名' }]}>
                    <Input placeholder='请输入姓名' />
                </Form.Item>
                <Form.Item label="公司名称" name="companyName" rules={[{ required: true, message: '请输入公司名称' }]}>
                    <Input placeholder='请输入公司名称' />
                </Form.Item>
                <Form.Item label="联系电话" name="phoneNumber" rules={[{ required: true, message: '请输入联系电话' }, { pattern: /^1[3456789]\d{9}$/, message: '请输入正确联系电话' }]}>
                    <Input placeholder='请输入联系电话' maxLength={11} />
                </Form.Item>
                <Form.Item label="所在地区" name="region" rules={[{ required: true, message: '所在区域' }]}>
                    <Input placeholder='所在区域' />
                </Form.Item>
                <Form.Item label="意向产品" name="productName" rules={[{ required: true, message: '意向产品' }]}>
                    <Input placeholder='意向产品' />
                </Form.Item>
                <Form.Item label="行业场景" name="situation">
                    <Input placeholder='行业场景' />
                </Form.Item>
                <Form.Item label="其他" name="description" >
                    <TextArea placeholder='其他' rows={3} />
                </Form.Item>
            </Form>
            <div className="page-btm fxy--center">
                <div className="save-btn" onClick={saveFrom}>提交</div>
            </div>
        </div>
    </div>;
};

export default ApplyTrialDom;
