export const TYPE_MAP = new Map([
    [1, 'string'],
    [2, 'number'],
    [3, 'array'],
    [4, 'object'],
    [5, 'boolean'],
    [6, 'integer']
]);
export function objTreeToArrayTree(obj: any) {
    const children: any = [];
    if (typeof obj !== 'object') return;
    if (TYPE_MAP.get(obj.type) === 'object') {
        if (Object.keys(obj.properties).length === 0) {
            // 对象里面没有键值对
            delete obj.properties;
            children.push({ ...obj });
        } else {
            Object.keys(obj.properties).forEach((key) => {
                const copyObj = { ...obj.properties[key] };
                if (TYPE_MAP.get(obj.properties[key].type) === 'object') {
                    // 对象里面是对象
                    if (
                        Object.keys(obj.properties[key].properties).length !== 0
                    ) {
                        delete copyObj.properties;
                        children.push({
                            ...copyObj,
                            children: objTreeToArrayTree(obj.properties[key])
                        });
                    } else {
                        children.push({ ...copyObj });
                    }
                } else if (TYPE_MAP.get(obj.properties[key].type) === 'array') {
                    // 对象里面是数组
                    if (
                        TYPE_MAP.get(obj.properties[key].items.type) ===
                        'object'
                    ) {
                        delete copyObj.items;
                        children.push({
                            ...copyObj,
                            children: [
                                {
                                    ...obj.properties[key].items,
                                    children: objTreeToArrayTree(
                                        obj.properties[key].items
                                    )
                                }
                            ]
                        });
                    } else {
                        children.push({
                            ...copyObj,
                            children: objTreeToArrayTree(
                                obj.properties[key].items
                            )
                        });
                    }
                } else {
                    children.push(copyObj);
                }
            });
        }
    } else if (TYPE_MAP.get(obj.type) === 'array') {
        const cloneObj = { ...obj };
        delete cloneObj.items;
        children.push({ ...cloneObj, children: objTreeToArrayTree(obj.items) });
    } else {
        // 不是对象和数组就直接push
        children.push(obj);
    }
    return children;
}

export const scrollToTop = () => {
    const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, currentScroll - currentScroll / 8);
    }
};

export default {
    // 随机唯一值（length：长度）
    uuid: (length: number = 20) => {
        const chars =
            '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
                ''
            );
        let str = '';
        for (let i = 0; i < length; i++) {
            const random = Math.floor(Math.random() * chars.length);
            str += chars[random];
        }
        return str;
    },
    getUrlParams(name: string) {
        let reg: any = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let r = window.location.search.substr(1).match(reg); // 获取url中"?"符后的字符串并正则匹配
        let context = '';
        if (r != null) {
            context = decodeURIComponent(r[2]);
        }
        reg = null;
        r = null;
        return context == null || context === '' || context === 'undefined'
            ? ''
            : context;
    }
};
