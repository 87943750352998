import React, { Suspense, useEffect, useState } from 'react';
import Routes from '@@/views/.route';
// import AppDefaultPC from '@@/views/modules'; // pc端
import AppDefaultPC from '@@/views/modules/pc'; // pc端
import AppDefaultMobile from '@@/views/modules/mobile';// 移动端
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import globalRouteMiddleware from '@@/middleware/global.route';
import { Provider } from 'mobx-react';
import intercept from '@@/intercept';
import { ConfigProvider } from 'antd';
import history from '@common/services/routeHistory.service';
import 'animate.css';
import './assets/scss/pxTorem.scss';
import 'tailwindcss/tailwind.css';

import './global.scss';

import 'tailwindcss/tailwind.css';

import { Router, Switch, Route } from 'react-router-dom';

window.addEventListener('resize', (e) => {
    updatedHtmlSize();
});

const homeSize = localStorage.getItem('homeSize');
if (homeSize) {
    const htmlDom = document.getElementsByTagName('html')[0];
    htmlDom.style.fontSize = homeSize + 'px';
}

function updatedHtmlSize() {
    const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    const htmlDom = document.getElementsByTagName('html')[0];
    htmlDom.style.fontSize = htmlWidth / 100 + 'px';
    localStorage.setItem('homeSize', String(htmlWidth / 100));
}

const App = () => {
    useEffect(() => {
        updatedHtmlSize();
    }, []);

    const isMobileDevice = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        return (
            /mobile|android|iphone|ipad|iemobile|wpdesktop|opera mini/i.test(userAgent)
        );
    };

    // const [isPc, setIsPc] = useState<boolean>(!isMobileDevice());
    const [isPc, setIsPc] = useState<boolean>(true);


    return (
        <Router history={history}>
            <Provider >
                <ConfigProvider locale={zhCN}>
                    <Switch>
                        <Suspense fallback={<></>}>
                            <Route exact path="/" component={globalRouteMiddleware(isPc ? AppDefaultPC : AppDefaultMobile as any, { meta: { intercept: intercept.route, title: '首页' } } as any)} />
                            {Routes.map((routeItem) => {
                                return (
                                    <Route
                                        exact
                                        key={routeItem.path}
                                        path={routeItem.path}
                                        component={globalRouteMiddleware(routeItem.component as any, routeItem)}
                                    />
                                );
                            })}
                        </Suspense>
                    </Switch>
                </ConfigProvider>
            </Provider>
        </Router>
    );
};

export default App;
