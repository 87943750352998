import React from 'react';
import './index.scoped.scss';
import ProductPonsultationFoot from '@@/components/ProductConsultation';
import { Helmet } from 'react-helmet';

const CoreTechnologyDom = () => {

    const openLink = () => {
        window.location.href = 'applyTrial';
    };

    return <>
        <Helmet>
            <title>金智塔科技-专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见</title>
            <meta property='og:title' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta property='og:description' content="专业的数据要素流通与隐私计算平台，公共数据授权运营，政务数据多跨共享，社会数据合规流通，数据可用不可见" />
            <meta name="description" content="金智塔科技是由浙江大学人工智能研究所和浙江大学金融科技研究院联合发起的数据合规流通与隐私计算领军企业，以“让数智世界更可信”为使命，自主研发了隐私计算平台、数据要素流通平台、安全计算平台、机器学习平台等产品，为政府部门、金融机构、大型企业等提供安全高效的数据流通和数据智能解决方案。" />
            <link rel="shortcut icon" href={require('@@/assets/img/favicon.png')} />
        </Helmet>
        {/* header */}
        <div className="relative core-header">
            <div className="absolute core-header-bg"></div>
            <div className="absolute flex core-header-center">
                <div className="flex-1 core-header-card">
                    <div className="core-header-title">打造卓越产品</div>
                    <div className="core-header-txt">坚持自主创新研发，以「技术+场景」双轮驱动产品升级与迭代</div>
                    <div className="core-header-btn fxy--center cup" onClick={openLink}>申请试用</div>
                </div>
            </div>
        </div>

        {/* 核心能力 */}
        <div className="core-capacity fxy--center">
            <div className="flex core-capacity-center">
                <div className="flex flex-1 f-fd-c">
                    <div className="core-capacity-header fxy--center">核心能力</div>
                    <div className="flex flex-1 core-capacity-card">
                        <div className="flex flex-1 core-capacity-box f-fd-c f-jc-sa">
                            <div className="relative capacity-box-card">
                                <div className="capacity-box-card-header">联邦学习</div>
                                <img className='absolute capacity-box-card-img' src={require('@@/assets/img/coreTechnology/capacityboxcardzjt.png')} alt="" />
                                <div className="capacity-box-card-txt">
                                    在一个(半)可信第三方的辅助下，多个参与
                                    方协同计算。实现“数据不动模型动”，保证
                                    在多方数据不出域的前提下，充分挖掘数据
                                    价值，解决“数据孤岛”问题。
                                </div>
                            </div>

                            <div className="relative capacity-box-card">
                                <div className="capacity-box-card-header">多方安全计算</div>
                                <img className='absolute capacity-box-card-img dfaq-fz' src={require('@@/assets/img/coreTechnology/capacityboxcardzjt.png')} alt="" />
                                <div className="capacity-box-card-txt">
                                    在无第三方参与计算的情况下，多个参与方
                                    协同计算。保证每一方获取自己计算结果，
                                    无法通过计算过程中的交互数据推测出其他
                                    任意一方的输入和输出数据。
                                </div>
                            </div>
                        </div>
                        <div className="relative core-capacity-img">
                            <img className='absolute core-capacity-img-xz' src={require('@@/assets/img/coreTechnology/coreTechnologyhxnlxz.gif')} alt="" />
                            <img className='absolute core-capacity-img-dz' src={require('@@/assets/img/coreTechnology/coreTechnologyhxnlxzdz.png')} alt="" />
                        </div>
                        <div className="flex flex-1 core-capacity-box f-fd-c f-jc-sa">

                            <div className="relative capacity-box-card">
                                <div className="capacity-box-card-header ta-r">可信执行环境</div>
                                <img className='absolute capacity-box-card-img' src={require('@@/assets/img/coreTechnology/capacityboxcardyjt.png')} alt="" />
                                <div className="flex f-jc-fe">
                                    <div className="capacity-box-card-txt">
                                        是一种安全的计算环境，通过硬件和软件的
                                        组合，能够确保计算过程的可靠性和保密性，
                                        防止恶意攻击和信息泄露。
                                    </div>
                                </div>
                            </div>

                            <div className="relative capacity-box-card">
                                <div className="capacity-box-card-header ta-r">沙箱</div>
                                <img className='absolute capacity-box-card-img dfaq-fz' src={require('@@/assets/img/coreTechnology/capacityboxcardyjt.png')} alt="" />
                                <div className="flex f-jc-fe">
                                    <div className="capacity-box-card-txt">
                                        是一种隔离和限制程序运行环境的技术，通
                                        过限制程序的访问权限和资源使用，用于防
                                        止恶意代码对系统的损害，并保护用户数据
                                        的安全。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="core-card fxy--center">
            <div className="core-card-center">
                <div className="flex-1">
                    <div className="core-card-header fxy--center">统一架构，支持联邦学习、多方安全计算及其混合计算模式</div>
                    <img className='core-card-img' src={require('@@/assets/img/coreTechnology/capacityboxcardimg1.png')} alt="" />
                </div>
            </div>
        </div>

        <div className="core-card1 fxy--center">
            <div className="core-card-center">
                <div className="flex-1">
                    <div className="core-card-header fxy--center">统一架构，支持可信执行环境与沙箱两种计算模式</div>
                    <img className='core-card-img' src={require('@@/assets/img/coreTechnology/capacityboxcardimg2.png')} alt="" />
                </div>
            </div>
        </div>

        <ProductPonsultationFoot />
    </>;
};

export default CoreTechnologyDom;
