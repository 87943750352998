import './global.scss';
import './style.scoped.scss';
import React from 'react';
import TopBar from './topbar';
import { withRouter } from 'react-router';
import Navbar from '@@/layout/admin/navbar';
import FooterBar from '@@/layout/admin/footerbar';
import FloatButtonDom from './FloatButton';

interface IProps {
    [key: string]: any
}

const AdminLayout = (props: IProps) => {
    const path = props.match.path;

    return (
        <div className="adminLayout">
            <div className="mainWrap">
                <div>
                    <div className="layout-fixed">
                        {/* <TopBar/> */}
                        <Navbar />
                    </div>
                    <div className="main">
                        {props.children}
                    </div>
                </div>
                <FooterBar />
            </div>
            <FloatButtonDom />
            {/* <FloatButton.BackTop /> */}
        </div>
    );
};

export default withRouter(AdminLayout);
